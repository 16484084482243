
$header-topbg-color: #6200EE;
$body-lightbg-color: #F5F5F5;
$text-black-color: #000000;
$text-color:#DF5227;
$ligth-card-color: #FFECDB;
$ligth-btn-color: #031C64;
$lightblue-color:#EBDBFE;
$landingbg: #f3fedc;
$whitecolor: #fff;
$light-orange:#FFEEDF;
 $lightbluebtn: #D4B1FF;
$mor-btnborder :#F29C4A;
$btnshadow:#c6a6f1;
$btntext-color:#1A1A1A;
$daycardbg:#EDEDED;
$dayseprator:#A3A3A4;
$anchorlink: #5723e5;
$btnprimatyhover:#ffaf62;
$errorcolor:#FF0000;
$btnsecondaryover:#8C4CFB;
$inputrangeevningover:#ba98e9;
$footerbgcolor:#fcfff5;
$partnerwidthtext:#323131;
// green theme color
$text-black-color: #000000;
$card-bg:#6EAE6C;
$slickdotbg:#F7F6E9;
$staticbg:#F7F9EE;
$headingtext:#DF5227;
$introbtn:#031C64;
$menuhover:#EAEFD2;
$cardorange: #EF7830;
$cardgreen: #488F45;
$carddkgreen: #2B7428;
$headergreen:#488F45;
$headerblue:#031C64;
$headergreenlight: #488F45;
$emotionbtncolor:#ED7738;
$emotionactivebtncolor:#E95E14;
$modelgreen:#488F45;

$emailheading:#031C64;








