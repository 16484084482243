

@import "./default-color";
@import "../../common/fontstyle";
@import "../../common/mediasize";
@import "../../common/header.scss";
@import "../../common/modelpopup.scss";
@import "../../common/left-nav.scss";
html, body{padding: 0; margin: 0;}
.pd-tb{padding:1rem 0;}
.pd-lr{padding:0 1rem;}
.pd-l{padding-left:1rem;}
.pd-r{padding-right:1rem;}
.tbgap{margin: 2rem 0 1rem 0;}
.padd-0{padding: 0!important;}
.video-btn-mar{ margin: 1rem 0;}

.mobile-view-container{
  width: 96%; padding: 29% 2% 5% 2%; margin: 0 auto;
    @include for-size(mobile-430){padding: 33% 2% 5% 2%;}
    @include for-size(mobile-428){padding: 33% 2% 5% 2%;}
    @include for-size(mobile-414){padding: 33% 2% 5% 2%;}
    @include for-size(mobile-393){padding: 35% 2% 5% 2%;}
    @include for-size(mobile-390){padding: 35% 2% 5% 2%;}
    @include for-size(mobile-375){padding: 35% 2% 5% 2%;}
    @include for-size(mobile-360){padding: 37% 2% 5% 2%;}
    }// Typography
.mobile-view-container h2{ 
  font-family: $fontstyle-allenoire;
  font-style: normal; 
  font-weight: 400; 
  font-size: 25px; 
  line-height: 120.5%;
color: $text-black-color; 
margin: 0.3rem 0 .5rem 0; 
    @include for-size(mobile-430){margin: 1rem 0 1rem 0;}
    @include for-size(mobile-428){margin: 1rem 0 1rem 0;}
    @include for-size(mobile-414){margin: 1rem 0;}
    @include for-size(mobile-390){margin: .5rem 0 .5rem 0;}
    @include for-size(mobile-375){margin: 0.3rem 0 .5rem 0;}
    @include for-size(mobile-360){margin: .5rem 0 .5rem 0;}
}
.mobile-view-container h3{ 
color: $text-black-color; 
  font-family: $fontstyle-allenoire;
font-size: 25px; 
font-style: normal; 
font-weight: 400; line-height: 28px; 
margin: 1rem 0; padding-top: 0; 
text-align: left!important;
}
.mobile-view-container h4{
  color: $header-topbg-color; 
  font-family: $fontstyle-allenoire;
  font-size: 1.563rem; 
  font-style: normal; 
  font-weight: 400;  
  line-height: 120.5%;
  text-align: left!important;  
  vertical-align: top;

}
  .mobile-view-container p{ 
  font-size: 14px; 
  color: $text-black-color;
  font-family: $fontstyle-lato;
  font-style: normal;  
  font-weight: 400; 
  line-height: 17px; 
  margin-bottom: 20px; 
  text-align: left;
  @include for-size(mobile-430){margin: 30px 0;}
  @include for-size(mobile-428){margin: 15px 0;}
  @include for-size(mobile-414){margin: 15px 0;}
  @include for-size(mobile-390){margin: 10px 0;}
  @include for-size(mobile-375){margin: 10px 0 !important;}
  @include for-size(mobile-360){margin: 10px 0;}
 
}
.mobile-view-container p span{font-weight: 700; display: block; margin-top: 9px;}
.static-pages{
P{
  font-size: 14px; 
  color: $text-black-color; 
  font-family: $fontstyle-lato; 
  font-style: normal;
  font-weight: 400; 
  line-height: 17px; 
  margin-bottom: 20px; 
  text-align: left;
a{ color: $text-black-color; font-weight: 700;}
}
.link-heading{
  font-size: 16px; 
  color: #5723e5; 
  margin: 1.5rem 0; 
  font-family: $fontstyle-lato;
 font-weight: 700;}
.profile-img{ 
  text-align: center; 
  margin: 1rem 0; 
  img {width: 40%;}}
.profile{
font-family: $fontstyle-lato; 
font-weight: 600;  
color: $header-topbg-color;
font-size: 16px; 
font-style: normal; 
line-height: 19px; 
margin: 0 auto 1.5rem;
text-align: center; 
width: 53%;
span{font-weight: 700; display: block;}
}}
.heading-text{ 
font-family: $fontstyle-lato;  
font-style: normal;  
font-weight: 400;  
font-size: 16px;
line-height: 19px;  
color: $text-black-color;  
margin: 0.3rem 0 2rem 0; 
span{ display: inline!important;
color:$header-topbg-color; 
font-weight: 700!important;}
}
.heading-text-title{ 
  @extend .heading-text;  
  margin: 0.3rem 0 1rem 0; 
  color: $anchorlink; }
.modal-header .modal-title.heading-text-title-popup{ 
  @extend .heading-text;  
  color: $anchorlink; font-weight: 700; margin: 0; }
 
.heading2-text{  
font-family: $fontstyle-lato;
font-style: normal;
font-weight: 400; 
font-size: 14px;
line-height: 17px;
color: $text-black-color;@extend .tbgap; 
margin: 1rem 0 1rem 0;
text-align: left;
@include for-size(mobile-430){margin: 4.5rem 0 1rem 0;}
@include for-size(mobile-428){margin: 4.5rem 0 1rem 0;}
@include for-size(mobile-414){margin: 3.5rem 0 1rem 0;}
@include for-size(mobile-390){margin: 2.5rem 0 .5rem 0;}
@include for-size(mobile-375){margin: 1.5rem 0 .5rem 0;}
@include for-size(mobile-360){margin: 1.5rem 0 .5rem 0;}
}
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      .heading2-text{text-align: left!important; margin: 1rem 0 1rem 0!important;} 
    }
.tap-text{
  width:100%; 
  text-align: center; 
  margin:0; 
  font-family: $fontstyle-lato; 
  font-style: normal; 
  font-weight: 700;
  font-size: 15px; 
  line-height: 120.5%; 
  color: $header-topbg-color;}

.light-bg{
  background-color: $footerbgcolor;  
  height: 100vh; 
  width: 100%!important;
}
.mid-content.mobile-bg{ background-color: $header-topbg-color; }
.mid-content{ 
  background-color: $landingbg;
   position: relative;
  overflow: hidden;
  width: 100%; 
  padding: 120px 0 0 0;
  @include for-size(mobile-428){padding: 80px 0 0 0;}
  @include for-size(mobile-414){padding: 80px 0 0 0;}
  @include for-size(mobile-393){padding: 80px 0 0 0;}
  @include for-size(mobile-390){padding: 91px 0 0 0;}
  @include for-size(mobile-375){padding: 90px 0 0 0;}
  @include for-size(mobile-360){padding: 87px 0 0 0;}

.home-center{width: 65%; margin: 0 auto;}
.content-center{width: 90%; margin: 0 auto;}
.get{
  color: $header-topbg-color;
  font-family: $fontstyle-lato;  
  font-size: 25px; font-style: normal;
  font-weight: 700; 
  letter-spacing: 0; 
  line-height: 20px; 
  text-align: center;
}
.qr{
  font-family: $fontstyle-lato;  
  font-size: 20px;  
  font-style: normal;  
  font-weight: 400;
  line-height: 1.5rem;  
  text-align: center;
}
.qr-img{
  text-align: center; 
  margin: 3rem 0; 
  img{width: 16%; 
  box-shadow: 1px 6px 87px 33px #fff;}
  }}
.curve-card{border-bottom-right-radius: 0px;}
h1{
font-family: $fontstyle-allenoire;
color: $text-black-color; 
font-size: 2.4rem;
font-weight: 400; 
line-height: 2.5rem; 
margin: 2rem auto 1.5rem; 
text-align: center;
}

//Cards
.card-slider{
  width: 70%; 
  margin: 0 auto; 
  display: flex; 
  justify-content: center;
.card{
  background: $whitecolor; 
  border-bottom-right-radius: 50px;  
  border-top-left-radius: 50px;
    height: 400px; 
    padding: 24px; 
    width: 302px; 
    border: none; 
    margin-right: 20px;     
    position: REVERT;
.card-heading{
  color:$header-topbg-color; 
  font-family: $fontstyle-allenoire; 
  font-size: 24px;
font-style: normal; 
font-weight: 400; 
line-height: 120.5%; 
margin: 0 0 1rem; 
text-align: left;}
.card-descriptions{
  size: 14px; 
  color: $text-black-color; 
  font-family: $fontstyle-lato; 
  font-style: normal;
font-weight: 400; 
line-height: 17px; 
margin-bottom: 20px; 
text-align: left;
span{font-weight: 700;}
}}
.card-img{
  text-align: center; 
  margin: 1rem auto; 
  .card-img-size1{width: 36%; margin-top: -12px;}
  .card-img-size2{width: 58%; margin-top: 50px;}
}
.card:last-child{margin-right: 0;}}
.card-img img.card-img-size{width:55%; margin-top: 40px; }
//cards
.btn-center{ 
  display: block; 
  margin: 1rem 0; 
  text-align: center; 
  width: 100%;
a{
  font-family: $fontstyle-lato; 
  font-style: normal; 
  font-weight: 700;  
  font-size: 12px;
  line-height: 14px;  
  text-align: center; 
  text-decoration:underline; 
  color: $text-black-color;
}}
.mar-0{margin: 0!important;}
.mar-btn{margin: .6rem 0;}
.btn-center .btn-primary.mar-btn{margin-top: 1rem;}
.f-left{ text-align: left;}
// Mobile home
.bgwhite{background-color: $whitecolor;}
.brushing{ position: absolute;
  left: 30%;
  top: -36%;
img{width: 130%;}
}
.glow-ellipes {  
  //background: url(../../../images/ellipse.png) no-repeat; 
  background: none;
  background-position-y: 139px;
    background-size: 100%;
    @include for-size(mobile-430){background-position-y: 42px;}
     @include for-size(mobile-428){background-position-y: 40px;}
      @include for-size(mobile-414){background-position-y: 51px;}
      @include for-size(mobile-390){background-position-y: 40px;}
      @include for-size(mobile-375){background-position-y: 40px;}
      @include for-size(mobile-360){background-position-y: 35px;}
    h1{
      font-size: 36px;
      //color:$text-black-color;
      color:$whitecolor;
      @include for-size(mobile-430){padding: 4% 0; font-size: 38px;}
      @include for-size(mobile-428){padding: 4% 0; font-size: 38px;}
      @include for-size(mobile-414){padding: 2% 0; font-size: 37px;}
      @include for-size(mobile-390){padding: 1% 0; font-size: 35px;}
      @include for-size(mobile-375){padding: 1% 0; font-size: 35px;}
      @include for-size(mobile-360){padding: 1% 0; font-size: 33px;}
      
      }
p{
  font-family: $fontstyle-lato; 
  //color: $header-topbg-color; 
  color: $whitecolor; 
  text-align: center; 
  font-size: 16px;
  font-style: normal; 
  line-height: 19.2px;
  width: 98%;
  margin: 0 auto;
    @include for-size(mobile-430){padding: 8% 0;}
    @include for-size(mobile-428){padding: 7% 0;}
    @include for-size(mobile-414){padding: 5% 0;}
    @include for-size(mobile-393){padding: 4% 0;}
    @include for-size(mobile-390){padding: 4% 0;}
    @include for-size(mobile-375){padding: 4% 0;}
    @include for-size(mobile-360){padding: 1% 0;}
 
  }
  }
// Mobile home

//Button
  .btn-primary { --bs-btn-color: none;--bs-btn-bg: none; --bs-btn-border-color: none; --bs-btn-hover-color: none;
    --bs-btn-hover-bg: none; --bs-btn-hover-border-color: none; --bs-btn-focus-shadow-rgb: none;
    --bs-btn-active-color: none;--bs-btn-active-bg: none;--bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;--bs-btn-disabled-color: none; --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;}

.btn-center .btn-primary{ 
  background: $ligth-btn-color;  
  border-color: transparent;
    border-radius: 30px; 
    box-shadow: 0 0 10px #ffd4ac; 
    color: $text-black-color;
    font-family: $fontstyle-lato; 
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 40px; 
    text-align: center; 
    width: 100%;
.btnwidth50{width: 50%; margin:0 auto;}
}
.btn-center .btn-primary.btnwidth90{width: 90%; margin:0 auto;}
.btn-center .btn-primary.btnresponsive{width: 96%; margin:0 auto;
  @include for-size(mobile-428){width: 93%;}
  @include for-size(mobile-414){width: 96%;}
  @include for-size(mobile-390){width: 96%;}
  @include for-size(mobile-375){width: 96%;}
  @include for-size(mobile-360){width: 96%;}
}
.btn-center .btn-primary.btn-pad{padding-top:1rem;}
.btn-center .btn.btn-primary:hover { 
  background: $btnprimatyhover; 
  color: $text-black-color; 
  border: none; 
} 
.btn-center .btn-secondary{
  @extend .btn-primary; 
  background: $lightbluebtn; 
  box-shadow: 0 0 10px $lightbluebtn;
  color: $text-black-color;
  }
.btn-center .btn.btn-secondary:hover{ 
  background: $btnsecondaryover; 
  color: $text-black-color; 
  border: none; 
}
.btn-center .btn-secondary,.btn-primary.btnwidth50{width: 50%; margin:0 auto;}  
.btn-position{position: relative; top: -14px;}
.btn-section{ 
width: 100%; 
margin: .3rem 0; 
float: left;
flex-wrap: wrap;
display: flex;
  @include for-size(mobile-428){margin: 1.5rem 0 2.5rem 0;}
  @include for-size(mobile-414){margin: 1rem 0 2.5rem 0;}
  @include for-size(mobile-390){margin: 1rem 0;}
  @include for-size(mobile-375){margin: 1rem 0;}
  @include for-size(mobile-360){margin: 1rem 0;}

.morbtn{
border: 1px solid $mor-btnborder; 
border-radius: 56px;  
background-color: $whitecolor;
height: 40px; 
color: $btntext-color; 
font-family: $fontstyle-lato; 
font-size: 14px;  
font-style: normal;
font-weight: 500;
margin: 5px;
box-shadow: 0 0 10px $ligth-btn-color;
padding: 0 17px;
@include for-size(mobile-430){margin: 5px 25px 5px 0;}
@include for-size(mobile-428){margin: 5px 25px 5px 0;}
@include for-size(mobile-414){margin: 5px 16px 5px 0;}
@include for-size(mobile-393){margin: 5px 18px 5px 0;}
@include for-size(mobile-390){margin: 5px 13px 5px 0;}
@include for-size(mobile-375){margin: 5px 12px 5px 0;}
@include for-size(mobile-360){margin: 5px 5px 5px 0;}
}
.morbtn.active{background: $ligth-btn-color;}
.evnbtn{border: 1px solid $header-topbg-color; @extend .morbtn; box-shadow: 0 0 10px $lightbluebtn;}
.evnbtn.active{background: $btnshadow;} } 
 //Button

 //footer
.footer{
  width: 98%; 
  padding: 1%; 
  text-align: center; 
  margin: 10px auto 0 auto;
p{
  font-family: $fontstyle-lato; 
  color: $header-topbg-color; 
  font-style: normal; 
  font-size: 14px;
  font-weight: 400; 
  line-height: 17px; 
  text-align: center; 
  margin: 0;
}
.footer-logo{width: 93%;  margin: 0 auto;
  @include for-size(mobile-428){width: 97%;}
  @include for-size(mobile-414){width: 97%;}
  @include for-size(mobile-390){width: 93%;}
  @include for-size(mobile-375){width: 93%;}
  @include for-size(mobile-360){width: 93%;}

.imgwdth{width: 46%;}
span{ 
font-family: $fontstyle-lato; 
color: $header-topbg-color; 
font-style: normal;
font-size: 14px; 
font-weight: 400; 
line-height: 17px; 
text-align: center;
}}}

.close-cross-icon{ 
  background: url("../../../images/menu-close.svg") no-repeat; 
  background-position: 140px 30px;
  background-size: 16.5%;}
.back-btn{background: url("../../../images/right-arrow.svg") no-repeat; 
  background-position: 1px 14px;
  display: inline-block; 
  left: 7px; 
  position: relative; 
  top: -6px;}
  .Image.Round{width: 86%!important; height: auto!important; margin: 0 auto;
  @include for-size(mobile-428){width: 96%!important;}
  @include for-size(mobile-414){width: 96%!important;}
  @include for-size(mobile-390){width: 72%!important;}
  @include for-size(mobile-375){width: 72%!important;}
  @include for-size(mobile-360){width: 75%!important;}
  }
// Video pages
.video-section-width{width: 95%; margin: 0 auto;}
.card-radius{
  border-end-end-radius: 50px;
  border-start-start-radius: 50px; 
  padding: 15px;
  margin: 20px 0;
  justify-content: space-between;
}
.left-icon{ padding: .8rem 0; margin: 4px 0px 0 40px;
  @include for-size(mobile-430){margin: 4px 0px 0 40px;}
  @include for-size(mobile-428){margin: 4px 0px 0 40px;}
  @include for-size(mobile-414){margin: 4px 0px 0 40px;}
  @include for-size(mobile-390){margin: 4px 0px 0 40px;}
  @include for-size(mobile-375){margin: 4px 0px 0 40px;}
  @include for-size(mobile-360){margin: 4px 0px 0 40px;}
}
.card-discriptions{
  font-family: $fontstyle-lato; 
  color: $text-black-color;   
  font-style: normal;
      font-weight: 700; 
      font-size: 18px;
      line-height: 120.5%;
      width: 59%; 
      margin: 16px 0 0 90px;
      @include for-size(mobile-430){width: 68%; margin: 16px 82px 0 40px;}
      @include for-size(mobile-414){width: 68%; margin: 16px 73px 0 30px;}
      @include for-size(mobile-390){width: 67%; margin: 16px 62px 0 24px;}
      @include for-size(mobile-375){width: 67%; margin: 16px 62px 0 24px;}
      @include for-size(mobile-360){width: 60%; margin: 16px 40px 0 17px;}      
    }
    .text-opacity{opacity: .5;}
    .slick-dots li{margin: 0!important;}
.video-card-discriptions{@extend .card-discriptions; width: 73%;

}
.right-icon{padding: 0;
img{ margin:11px 0 -5px 0;}
}
.ampm-text{ 
  width: 66%; 
  color: $text-black-color; 
  font-family: $fontstyle-allenoire;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  text-align: left!important;
}
.ampm-img{ 
margin-bottom: 25px;
img{width: 100%;}
}
.morning-color{
background-color: $light-orange;
box-shadow: 0px 0px 10px $light-orange;
.sperator{ 
  width: 100%; 
  margin: 1rem 0;
  border-bottom: 1px solid $mor-btnborder;
}
}
.evening-color{
background-color: $lightblue-color;
box-shadow: 0px 0px 10px $lightblue-color;
.sperator{ 
  width: 100%; 
  margin: 1rem 0;
  border-bottom: 1px solid $btnsecondaryover;
}
}
.card-gap{
padding: 0 12px;
position: relative;
}
.content-center-for-remaining-screen{ position: absolute; bottom: 10px; left: 15%;
  @include for-size(mobile-430){left: 15%;}
  @include for-size(mobile-428){left: 15%;}
  @include for-size(mobile-414){left: 15%;}
  @include for-size(mobile-390){left: 12%;}
  @include for-size(mobile-375){left: 12%;}
  @include for-size(mobile-360){left: 9%;}
}
.slick-dots li button:before {
font-size: 16px;
}
.slick-dots li.slick-active button:before {
  opacity: .75;  
  color: $header-topbg-color;
}
.card-layer{
@extend .card-radius;
padding: 16px 5px; 
@include for-size(mobile-375){margin: 5px 0 20px 0;}
@include for-size(mobile-360){margin: 5px 0 20px 0;}
}
.slider-card{
  @extend .card-radius;
  background-color: $landingbg; 
  height: 420px; 
  @include for-size(mobile-430){height: 510px;}
  @include for-size(mobile-428){height: 500px;}
  @include for-size(mobile-414){height: 465px;} 
  @include for-size(mobile-393){height: 430px;}
  @include for-size(mobile-375){height: 420px; margin: 10px 0; }
  @include for-size(mobile-360){height: 407px; margin: 10px 0; }

}
.slider-card.cardpadding{
padding: 1rem 2rem 1rem 2rem;
@include for-size(mobile-430){padding: 2rem;}
@include for-size(mobile-428){padding: 2rem;}
@include for-size(mobile-414){padding: 2rem;}
@include for-size(mobile-393){padding: 2rem 1rem;}
@include for-size(mobile-390){padding: 3rem 1rem;}
@include for-size(mobile-375){padding: 2rem 1rem;}
@include for-size(mobile-360){padding: 2rem 1rem;}
}
.morning-card{
  @extend .card-radius; 
  background-color: $landingbg;
}
.morning-card .sperator{ 
  width: 100%; 
  margin: 1rem 0;
  border-bottom: 1px solid $header-topbg-color;
}
.day-card{
  @extend .card-radius; 
  background-color: $daycardbg;
}
.day-card .sperator{ 
  width: 100%; 
  margin:0;
  border-bottom: 1px solid $dayseprator;
}
.evning-card{
  @extend .card-radius; 
  background-color: $lightblue-color;
}
.evning-card .sperator{ 
  width: 100%; 
  margin:0;
  border-bottom: 1px solid $dayseprator;
}
.video-card{
  @extend .card-radius; 
  background-color: $lightblue-color;
}
.video-card .sperator{ 
  width: 100%; 
  margin: 1rem 0;
  border-bottom: 1px solid $dayseprator;
}
.video-section{ 
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  margin: 1rem 0 3rem 0;
.video-paly-btn{
  width:32px; 
  height: 32px; 
  text-align: center;
}
.number{
  font-family: $fontstyle-lato; 
  font-style: normal; 
  font-weight: 700;
  font-size: 14px; 
  line-height: 120.5%;
}
.black-play-btn{ 
  @extend .video-paly-btn;  
  background: url("../../../images/play-video-active.svg") no-repeat;
  @extend .number; 
  p{margin-top: 42px !important; text-align: center;
  }}
.orange-play-btn{
  @extend .video-paly-btn; 
  background: url("../../../images/orange-tick.svg") no-repeat;
  @extend .number; 
  p{margin-top: 42px;}
}
.orange-tick-btn{ 
  @extend .video-paly-btn;  
  background: url("../../../images/orange-tick.svg") no-repeat;
  @extend .number; 
  text-align: center; 
  p{margin-top: 42px !important; text-align: center;} 
}
.purple-play-btn{ 
  @extend .video-paly-btn;  
  background: url("../../../images/purple-play-btn.svg") no-repeat;
  @extend .number; 
  p{margin-top: 42px!important; text-align: center;}
}
.play-btn{ 
  @extend .video-paly-btn;  
  background: url("../../../images/play-btn-icon.svg") no-repeat;
  @extend .number; 
  text-align: center!important;
  p{
    margin-top: 42px !important; 
    color: $text-black-color; 
    opacity: .5; 
    text-align: center;}
}}
.col-rev {flex-direction: column-reverse !important;}
.hide-div{ display:none;}
  //Range slider
  .range-details{ 
    justify-content: space-between; 
    width: 100%;
  P{@extend .heading-text; 
    color: $text-black-color; 
    line-height: 120.5%; 
    font-weight: 400;}}
  // Video paly page
.ex-details{display: flex;
.client-logo{
  img{
    border-bottom: 1px solid rgba(86, 118, 18, 0.29); 
    margin:14px 0 8px 0;
    padding-bottom: 7px; 
    width: 53%;
  }
.name{
  color: $anchorlink; 
  font-family: $fontstyle-lato; 
  font-size: 14px;
  font-style: normal;
  line-height: 131.5%;
  font-weight: 700;
}
.designation{@extend .name; 
  font-weight: 400;
}}
.ex-pic{img{width: 74px;}}}
  .halleon-logo{margin-left: -7px;}
//Range slider
input[type="range"]::-webkit-slider-thumb {
  height: 34px !important; 
  width: 34px !important; 
  margin-top: -15px !important;
}
/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  height: 34px !important; 
  width: 34px !important; 
  margin-top: -15px !important;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb { 
  height: 34px !important; 
  width: 34px !important; 
  margin-top: -15px !important;
}
//Range slider
.evening-range{ margin-top: 20px;}
.evening-range input[type="range"]::-webkit-slider-thumb {
  background: $header-topbg-color !important; 
  box-shadow: 2px 2px 8px 4px $inputrangeevningover!important;}
/* All the same stuff for Firefox */
.evening-range input[type="range"]::-moz-range-thumb {
  background: $header-topbg-color !important;
  box-shadow: 2px 2px 8px 4px $inputrangeevningover!important;}
/* All the same stuff for IE */
.evening-range input[type="range"]::-ms-thumb { 
  background: $header-topbg-color !important;
  box-shadow: 2px 2px 8px 4px $inputrangeevningover!important;}
  .morning-range{margin-top: 20px;}
.morning-range input[type="range"]::-webkit-slider-thumb {
  background: $mor-btnborder !important;
  box-shadow: 0px 0px 10px $mor-btnborder!important;}
/* All the same stuff for Firefox */
.morning-range input[type="range"]::-moz-range-thumb {
  background: $mor-btnborder !important; 
  box-shadow: 0px 0px 10px $mor-btnborder!important;
}

/* All the same stuff for IE */
.morning-range input[type="range"]::-ms-thumb {
  background: $mor-btnborder !important;
  box-shadow: 0px 0px 10px $mor-btnborder!important;}
.mornning-range-circle{
  background: $ligth-btn-color !important; 
  height: 34px !important; width: 34px !important;
  box-shadow: 2px 2px 8px 4px 
  $ligth-btn-color!important; 
  margin-top: -15px !important;
}
.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label{top:7px !important;}
.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret{top:1px !important;}
// range slider end

// Craousel
.carousel-control-next, .carousel-control-prev{ display: none!important;}
.carousel-indicators{ bottom:-32px!important;}
.carousel-indicators button{
  width: 6px!important; 
  height: 6px!important; 
  border-radius: 50%!important; 
  background: #dfdddd!important; 
  font-size: 2rem!important; 
  opacity: 9!important;
}
  .carousel-indicators button.active{
    background: $header-topbg-color!important;
  }
  .carousel-caption{ position: relative!important;}
.carousel-caption h3{
    color: $header-topbg-color; 
    font-family: $fontstyle-allenoire; 
    font-size: 1.563rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120.5%; 
    text-align: left!important;
    vertical-align: top;
  }
  .carousel-caption p{
    font-size: 14px; 
    color: $text-black-color; 
    font-family: $fontstyle-lato;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; 
    margin-bottom: 20px; 
    text-align: left;
  }
  .carousel-item{ margin: 0 10px; background: none;}
  
// switch button
.btn-info{ 
  background-color: #dfdddd; 
  border-radius: 50px;
}
.switch-btn{
  display: flex;
  margin: 20px auto;
  width: 180px;

  .switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 50px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $daycardbg;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider-blue:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 0;
    bottom:0;
    -webkit-transition: .4s;
    transition: .4s;  
    border-radius: 50%;
    background-color:$header-topbg-color;
    box-shadow: 0px 0px 10px $header-topbg-color;
  
  }
  .slider-peach:before{ position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 0;
    -webkit-transition: .4s;
    transition: .4s;
    bottom: 0;
    border-radius: 50%;
    background-color:$mor-btnborder; 
    box-shadow: 0px 0px 10px $mor-btnborder; 
    }  
  input:checked + .slider:before {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
    
    
  }
    /* Rounded sliders */
  .slider.round {
    border-radius: 100px;
    height: 45px;
    filter: drop-shadow(0px 0px 10px rgba(196, 196, 196, 0.83));
  }  
  .slider.round:before {
    border-radius: 50%;
  }
  .subtitle-label{
    font-family: $fontstyle-lato;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $text-black-color;
    margin:15px 10px;
    width: fit-content;
    margin-right: 10px;
  }
  .onoff-font{
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    font-family: $fontstyle-lato;
    color: $text-black-color;
  } 
  .toggle-off{
    position: absolute;
    right: 13px;
    top: 17px;
    @extend .onoff-font;
  }
  .toggle-on{
    position: absolute;
    left: 13px;
    top: 17px;
    @extend .onoff-font;
  }
}
// switch button

//Model Popup Button

.btn-heading{
font-family: $fontstyle-lato; 
font-style: normal; 
font-weight: 700;
font-size: 16px;  
line-height: 19px; 
color:$header-topbg-color;width: 100%; 
margin: .2rem 0; 
float: left;
text-align: left;}
.pop-button-ohimpact{ 
  background-color: $landingbg;  
  border-radius: 50px; 
  border: 1px solid $header-topbg-color; 
  font-family: $fontstyle-lato;
  font-style: normal;   
  font-size: 14px;  
  line-height: 20px; 
  text-align: center; 
  padding: 8px 15px; 
  margin-bottom: 15px; 
  margin-right: 11px; 
  white-space: nowrap;
  box-shadow: 0px 0px 5px $header-topbg-color;
  color: $text-black-color;
  font-weight: 500;
}
.pop-button-ohimpact:hover{
  background-color: $header-topbg-color; 
  color: $whitecolor;
  box-shadow: 0px 0px 10px $header-topbg-color;
}
.pop-button-ohimpact.active{
  background-color: $header-topbg-color; 
  color: $whitecolor;
  box-shadow: 0px 0px 10px $header-topbg-color;
  }

  //Form components
  .text-color-danger{
    width: 100%; 
    float: left; 
    text-align: left; 
    color: $errorcolor;
  }
  .form-control{
    border: 1.5px solid $header-topbg-color; 
    box-shadow: 0px 0px 5px rgba(98, 0, 238, 0.5);
    border-radius: 9px; 
    line-height: 1.9;
  }
  .form-control.custom-input{ 
    border:none;  
    background: $whitecolor; 
    border-radius: 18px!important; 
    box-shadow: none; 
    margin: 10px 0; 
    line-height: 39px;
  }
  .form-control.custom-input::placeholder{
  font-style: italic;}
  .form-group{
  margin-bottom: 15px;
  label{
    float: left; 
    margin-bottom: 8px; 
    font-family: $fontstyle-lato;
    font-style: normal; 
    font-weight: 700; 
    font-size: 12px; 
    line-height: 14px; 
    color: $text-black-color;}
  .form-check-label{ float: left;} 
  .form-control{@extend .form-control;}}
  .form-check-label a{color:$text-black-color!important; font-weight: 900;}
  .form-check{ 
    margin-top: 20px; 
    padding-left: 0!important;  
    display: flex;  
    justify-content: space-between;
  .form-check-label{ 
    float: left; 
    width: 83%; 
    text-align: left; 
    margin-right: 33px; 
    font-family: $fontstyle-lato;
    font-style: normal; 
    font-weight: 400; 
    font-size: 12px; 
    line-height: 14px; 
    color: $text-black-color;
  }
  .form-check-input { 
    float: revert; 
    width: 30px; 
    height: 30px; 
    @extend .form-control;
  }}
  .card-round {
    position: relative;
    width: 120%;
    height: 240px;
    margin: 50px 0 0 0;
    background: $landingbg;
    border-radius: 50% / 43%;
    text-indent: 0.1em;
    bottom:-80px;
    left: -32px;
    @include for-size(mobile-430){height: 260px;}
      @include for-size(mobile-428){height: 260px;}
      @include for-size(mobile-414){height: 250px;}
      @include for-size(mobile-390){height: 260px;}
      @include for-size(mobile-375){height: 234px;}
      @include for-size(mobile-360){height: 234px;}
   }
   @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      .card-round{height: 230px!important;} 
    }
.btn-center .btn-primary.landing-btnpos{position: relative; bottom: -101px; width: 70%;}

