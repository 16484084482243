@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'), url(../../fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  font-weight: 700;
  src: local('Lato'), url(../../fonts/Lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Medium';
  src: local('Lato'), url(../../fonts/Lato/Lato-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Allenoire Free Personal';
  font-weight: 400;
  src: local('Allenoire Free Personal'), url(../../fonts/Allenoire-Font.otf) format('opentype');
}

$fontstyle-allenoire: Allenoire Free Personal, sans-serif;
$fontstyle-lato: Lato, sans-serif;
$fontstyle-lato-medium: Lato-Medium, sans-serif;
$fontstyle-lato-bold: Lato-Bold, sans-serif;



@font-face {
  font-family: 'Diodrum-Regular';  
  src: local('Diodrum-Regular'), url(../../fonts/Diodrum-Regular/Diodrum-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum-Medium';  
  src: local('Diodrum-Medium'), url(../../fonts/Diodrum-Medium/Diodrum-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum-Bold';  
  src: local('Diodrum-Bold'), url(../../fonts/Diodrum-Bold/Diodrum-Bold.ttf) format('truetype');
}
$fontstyle-diodrum: Diodrum-Regular, sans-serif;
$fontstyle-diodrum-medium: Diodrum-Medium, sans-serif;
$fontstyle-diodrum-bold: Diodrum-Bold, sans-serif;