
@mixin for-size($size) {
    @if $size == mobile-360 {
      @media (max-width: 360px) {@content;}
    } @else if $size == mobile-375 {
      @media (max-width: 375px) {@content;}
    } @else if $size == mobile-390 {
      @media (max-width: 390px) {@content;}
    } @else if $size == mobile-393 {
      @media (max-width: 393px) {@content;}
    } @else if $size == mobile-414 {
      @media (max-width: 414px) {@content;}
    } @else if $size == mobile-428 {
      @media (max-width: 428px) {@content;}
    } @else if $size == mobile-430 {
      @media (max-width: 430px) {@content;}
    }
  }


