@import "./default-color";
@import "../../../../../assets/css/common/mediasize-v3";
@import "../../../../../assets/css/common/fontstyle";
@import "../../../../../assets/css/common/modelpopup-theme.scss";

html, body{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
.pm0{ padding: 0; margin: 0;}
.pd-lr{padding: 0;}

.hide-div{display:none}
.bg-inner-page{
  background-color: $text-white-color !important;
}


// typography
.text-heading{
    font-family: $fontstyle-allenoire;
    font-style: normal;
    font-weight: 400;
    font-size:9vw;
    line-height: 120.5%;
    @include for-size(mobile-360){ line-height: 100%;}
    @include for-size(mobile-430){ font-size:11vw;}
}
.home-text-balck{
    font-style: normal;
        font-size: 16px;
    line-height: 120.5%;
    font-family: $fontstyle-lato-bold;
}
.container{ @extend .pm0; height: 100%;}
.form-check {
  margin-top: 0px;}
  .btn-center a {
    color: $text-black-color!important;
    font-weight: 700;
    font-size: 12px !important;
    font-family: $fontstyle-lato-bold !important;}
.text-color-danger {
  width: 100%;
  float: left;
  text-align: left;
  color: $header-topbg-color;
  padding:0 10px 0 0%;
  margin-bottom: 10px;
  font-weight: bold;
}
  
.lotus-blue{  @extend .pm0;
  video::-webkit-media-controls-play-button {
    display: none !important;
  }
  video::cue {
    color: #fff;
    background-color:#000;
    font-size: 18px;
    font-weight: 900;
    font-family: $fontstyle-lato;
    
  }
  // left menu
.nav-menu{ background: $header-topbg-color !important; z-index: 9998;left: -100%; }
.nav-menu.active{left: 0 !important; transition: 350ms;}
.nav-menu ul {
  padding: 0;
  margin: 70px 0 0 0 !important;
}
.nav-text a{ color: $text-white-color!important; font-family: $fontstyle-lato-bold; font-size: 16px;
}
.nav-text a:hover{ background: $menuhover!important;}
// left menu 
.bg-landing{ @extend .pm0;
//header
.header{text-align: center; background: $header-topbg-color; position: fixed;
  z-index: 9999;
  width: 100%;  
  padding: 4.2% 0; 
  @include for-size(mobile-360){ height:70px;}
  @include for-size(mobile-375){ height:70px;}
  @include for-size(mobile-390){ height:71px;}
  @include for-size(mobile-393){ height:70px;}
  @include for-size(mobile-412){ height:73px;}
  @include for-size(mobile-414){ height:75px;}
  @include for-size(mobile-428){ height:74px;}
  @include for-size(mobile-430){ height:75px;}
  .back-button{
    background: url("../../../../../assets/images/images-v3/blue-back-btn-w.svg")no-repeat;
    width: 42px; 
    height: 22px;     
    margin:8px 10px 8px 20px;
  }
  img{padding: 2% 0; width: max-content;}
}
.desktop-header{background-color: $header-topbg-color; width:100%; text-align: center; 
  padding: 28px 0; position: fixed;

}
 
.header-sub{font-size: 2rem;}
.header-therapy{font-size: 2rem; 
  &.header-o{background-color: $whitecolor !important;}
}
//header end

//mid container
.img-border{border: 1px solid #ccc; border-radius: 50%; padding: 20px;}
.img-border2{border: 1px solid #ccc; border-radius: 50%; padding: 20px;}
.home-mid-container{  
  background-color: #F7F6E9; 
  .top-curve {    
    background: url(../../../../../assets/images/images-v3/top-corner-curve.svg) no-repeat;
    height: 61px;
    left: -1px;
    position: fixed;
    top: 67px;
    width: 40px;
    z-index: 9999;
    @include for-size(mobile-360){ top: 67px;}
    @include for-size(mobile-375){ top: 70px;} // 12 min
    @include for-size(mobile-390){ top: 70px;}
    @include for-size(mobile-393){ top: 70px;}
    @include for-size(mobile-412){ top: 73px;}
    @include for-size(mobile-414){ top: 73px;}
    @include for-size(mobile-428){ top: 74px;}
    @include for-size(mobile-430){ top: 74px;}
    @include for-size(mobile-730){ top: 78px;}
}
.home-content{top: 30px; height: 50vh;


}
.home-body-content{
  @extend .pd-lr; 
  text-align: center; 
  margin: 0;
  background: $header-topbg-color;   
  position: relative; top:13px; overflow: hidden;
  border-radius: 0% 0% 50% 50%/0% 0% 2% 2%;

   
  .home-heading{color: $text-white-color; @extend .text-heading; width: 90%; text-align: center; 
    
    margin: 27% auto 5% auto;
    @include for-size(mobileh-664){margin: 20% auto 5% auto;}
  }
 

  .gel-bg{ 
    background-position-x: 10px;
    background: #D4B1FF;
    border-radius: 50%/43%;
    bottom: -69px;
    height: 274px;
    left: -32px;
    margin: 50px 0 0;    
    text-indent: 0.1em;
    width: 120%;
    @include for-size(mobile-360){height: 249px;}
    @include for-size(mobile-375){height: 251px;} // 12 mini
    @include for-size(mobile-390){height: 259px;} // 13 pro, iPhone 14 Pro
    @include for-size(mobile-393){height: 270px;}
    @include for-size(mobile-412){height: 308px;}
    @include for-size(mobile-414){height: 234px;}
    @include for-size(mobile-428){height: 291px;}
    @include for-size(mobile-430){height: 280px;}
 
    .gel-tube{ position: absolute;
      left: 33%;
      top: -40%;
      @include for-size(mobile-360){top: -45%; left: 22%;}
      @include for-size(mobile-375){top: -44%; left: 23%;}
      @include for-size(mobile-390){top: -47%; left: 23%;}
      @include for-size(mobile-393){top: -39%; left: 23%;}
      @include for-size(mobile-412){top: -28%; left: 24%;}
      @include for-size(mobile-414){top: -35%; left: 24%;} // iphone XR
      @include for-size(mobile-428){top: -32%; left: 24%;}
      @include for-size(mobile-430){top: -32%; left: 24%;}
      
    img{ width:100%;
      @include for-size(mobile-360){width:70%;}
      @include for-size(mobile-375){width:80%;}
      @include for-size(mobile-390){width:75%;}
      @include for-size(mobile-414){width:90%;} // iphone XR
      @include for-size(mobile-428){width:90%;} // 13 pro max
    }
    @media only screen and (min-height: 727px)
{ 
  img{ width: 100%; } // 13 pro max
}


   
    }
      .landing-btn-pos{ 
        position: absolute; bottom: 93px;
        left: 11%;
        width: 75%;
        @include for-size(mobile-360){bottom: 124px;}
        @include for-size(mobile-375){bottom: 120px;} // 12 mini
        @include for-size(mobile-390){bottom: 122px;} // 13 pro
        @include for-size(mobile-414){bottom: 93px;}
        @include for-size(mobile-428){bottom: 125px;}
        @include for-size(mobile-430){bottom: 125px;}
      }
      @media only screen and (min-height: 727px){.landing-btn-pos{bottom:120px; }}
     }
  
     @media only screen and (min-height: 693px)
     { 
      .gel-bg {height: 288px;} // iphone XR
     }
     @media only screen and (min-height: 727px) // google pixel 5
{ 
  .gel-bg {height: 324px;}
}
     @media only screen and (min-height: 746px) // google pixel 6
     { 
      .gel-bg {height: 338px;}
     }
     
  p{
    @extend .home-text-balck; 
    color: $text-white-color;
    width: 88%;
    margin: 5% auto; 
    @include for-size(mobile-360){width: 92%;}
  } 
}
// static pages
.expert{ text-align: center; padding: 25% 0 0 0;
  img{margin-bottom: 20px; width: 53%;}
  p{
    font-family: $fontstyle-lato-medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
text-align: center;
color: $header-topbg-color;
   span{font-weight: 700; display: block; font-family: $fontstyle-lato-bold;} 
  }
} 

  .static-content{
    background: $landingbtn-color;
    padding: 8% 5%;
    @include for-size(mobile-360){height: 60vh;}
        @include for-size(mobile-375){height: 60vh;}
        @include for-size(mobile-390){height: 60vh;}
        @include for-size(mobile-414){height: 56vh;}
        @include for-size(mobile-428){height: 60vh;}
        @include for-size(mobile-430){height: 65vh;}
   
  
    .heading{
      font-family: $fontstyle-lato-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: $text-black-color;
      margin-bottom: 30px;
    }
    P{ 
      font-family: $fontstyle-lato;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 20px;
      color: $text-black-color;
    }
  }
  .terms{padding:25% 5% 10% 5%;
img{ margin: 0px 0 30px -5px;}
    .heading{
      font-family: $fontstyle-lato-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: $text-black-color;
      margin-bottom: 20px;
    }
.term-heading{
  font-family: $fontstyle-lato-bold;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: $headingtext;
margin-bottom: 20px;
}
    p{
      font-family: $fontstyle-lato;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 20px;
      color: $text-black-color; 
     a{font-weight: 700; color: $text-black-color;}
      }
      p:last-child{
        padding-bottom: 40px;  
      }
  }

   // static pages
}
//home mid container end

}

.footer{background-color: #F7F6E9; width: 76%; margin: 5% auto 0 auto;
  padding: 2% 0;
  @include for-size(mobile-360){margin: 5% auto 0 auto; padding: 2% 0;}
  @include for-size(mobile-375){margin: 5% auto 0 auto; padding: 2% 0;}
  @include for-size(mobile-390){margin: 5% auto 0 auto; padding: 7% 0;}
  @include for-size(mobile-393){margin: 0 auto; padding: 6% 0;}
  @include for-size(mobile-412){margin: 2% auto 0 auto; padding: 2% 0;}
  @include for-size(mobile-414){margin: 5% auto 0 auto; padding: 9% 0;}
  @include for-size(mobile-428){margin: 5% auto 0 auto; padding: 3% 0; width: 64%;}
  @include for-size(mobile-430){margin: 5% auto 0 auto; padding: 4% 0;  width: 64%;}

P{font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color:$header-topbg-color;
    font-family: $fontstyle-lato;
    margin: 7px 10px 0 0;
    &.top-padding{
      padding-top: 7px;
      padding-left: 10px;
    }
}
.footer-logo{ margin: 0 0px 0 0; width: auto; 

  img{width: 153px;}

&.top-padding{
  padding-top: 6px;
    
}
}

}
@media only screen and (max-height: 664px)
   { 
    .footer
     {padding: 6% 0 !important;}
   }
@media only screen and (max-height: 693px)
   { 
    .footer
     {padding: 2% 0 6% 0 !important;} // 12 mini, 13 mini, iphone SX
   }
 
   @media only screen and (min-height: 727px) // google pixel 5 
   {.footer
    {padding: 7% 0 !important;}}

   @media only screen and (min-width: 390px) and (min-height: 664px)
   { .footer
    {padding: 6% 0 !important;} //  12 pro
   }

   @media only screen and (min-width: 393px) and (min-height: 721px)
   { .footer
    {padding: 7% 0 7% 0 !important;} //google pixel 5
   }
  
@media only screen and (min-width: 412px) and (min-height: 746px)
{ .footer
  {padding: 8% 0 !important;} 
}
@media only screen and (min-width: 428px) and (min-height: 746px)
{ .footer
  {padding: 2% 0 4% 0 !important;} // 13 pro max, 12 pro max
}
@media only screen 
        and (device-width: 428px) 
        and (device-height: 926px) 
        and (-webkit-device-pixel-ratio: 3) { 
 .footer{padding: 2% 0 4% 0 !important;} } //  14 pro max  

//Button
.btn-primary { --bs-btn-color: none;--bs-btn-bg: none; --bs-btn-border-color: none; --bs-btn-hover-color: none;
    --bs-btn-hover-bg: none; --bs-btn-hover-border-color: none; --bs-btn-focus-shadow-rgb: none;
    --bs-btn-active-color: none;--bs-btn-active-bg: none;--bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;--bs-btn-disabled-color: none; --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;}

.btn.btn-primary{ 
  background: $landingbtn-color;  
  border-color: transparent;
    border-radius: 30px; 
    box-shadow: 0 0 10px $landingbtn-color; 
    color: $text-black-color;
    font-family: $fontstyle-lato-bold; 
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 42px;
    text-align: center; 
    width: 100%;
.btnwidth50{width: 50%; margin:0 auto;}

}
.btn-position-home{position: absolute; bottom: 20px;
  @include for-size(mobile-360){bottom: 0px;}
  @include for-size(mobile-375){bottom: 0px;}
  @include for-size(mobile-390){bottom: 5px;}
  @include for-size(mobile-393){bottom: 20px;}
  @include for-size(mobile-414){bottom: 20px;}
  @include for-size(mobile-428){bottom: 2px;}
  @include for-size(mobile-430){bottom: -15px;}
}
.btn-position{
position: relative;
top: -14px;
text-align: center;
}
.btn-position .btn.btn-primary.btnwidth80{width: 80%; margin:0 auto;}
.btn-position .btn.btn-primary.introbtn{ background: $introbtn; box-shadow: 0px 0px 10px $introbtn;    width: 92%;}

//Button
// top menu
.top-menu{
  margin: 6px 20px 6px 20px;

  .bar1 {
    width: 30px;
    height: 4px;
    border-radius: 5px;
    background-color: $text-white-color;
    margin: 6px 0;
    transition: 0.4s;
  }

  .bar2 {
    width: 18px;
    @extend .bar1;   
  }
  &.change{
 .bar1 {
    transform: translate(0, 10px) rotate(-45deg);
  }
  .bar2 {
    transform: translate(0, -0px) rotate(45deg);
    width: 30px;
  }
}
}
// Top menu
// card-slider
.slick-slide{ 

.card-gap {
  padding: 90px 17px 40px 17px;
  position: relative;
  @include for-size(mobile-360){padding: 100px 17px 40px 17px;}
  @include for-size(mobile-375){padding: 100px 17px 40px 17px;}
  @include for-size(mobile-390){padding: 100px 17px 40px 17px;}
  @include for-size(mobile-414){padding: 120px 17px 40px 17px;}
  @include for-size(mobile-428){padding: 120px 17px 40px 17px;}
  @include for-size(mobile-430){padding: 120px 17px 40px 17px;}

.crad-slider{
 
  .card{
    width:auto;
    padding: 25px;
    background: #F7F9EE;
    border-radius: 0;
    height: 450px;
    border-end-end-radius: 50px;
    border-start-start-radius: 50px;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    border: none;
    @include for-size(mobile-428){height: 450px;}
    @include for-size(mobile-414){height: 450px;}
    @include for-size(mobile-390){height: 430px;}
    @include for-size(mobile-375){height: 430px;}
    @include for-size(mobile-360){height: 400px;}
    img{
    width:80%;
    margin: 0 auto;
    @include for-size(mobile-360){width:80%;}
    @include for-size(mobile-375){width:80%;}
    @include for-size(mobile-390){width:80%;}
    @include for-size(mobile-393){width:80%;}
    @include for-size(mobile-412){width:70%;}
    @include for-size(mobile-414){width:80%;}
    @include for-size(mobile-428){width:65%;}
    @include for-size(mobile-430){width:65%;}
       
    }
h4{
  font-family: $fontstyle-lato-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120.5%;
color: $header-topbg-color;
margin: 10% 0 5% 0;
 }
    p{
      font-family: $fontstyle-lato;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
color: $text-black-color;
    }
  }
}
}
}
// card-slider
.slick-dots{ 
  position: absolute;
  border-radius: 20px !important;
  padding: 2px 0 5px 0 !important;
 
}
.slick-dots li button:before{
  color: #D4B1FF !important;
  opacity: 10!important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: $header-topbg-color!important;
}
// video-home

.video-home{
  padding-top: 80px;
  h1{
  font-family: $fontstyle-lato-medium;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 120.5%;
  color: $btntext-color;
  text-align: left;
  margin: 5% 5%;
  @include for-size(mobile-360){margin: 5% 5% 0% 5%; font-size: 28px; }
@include for-size(mobile-375){margin: 5% 5% 1% 5%; font-size: 32px;}
@include for-size(mobile-390){margin: 5% 5% 1% 5%; font-size: 32px;}
@include for-size(mobile-412){margin: 5% 5% 1% 5%; font-size: 35px;}
@include for-size(mobile-414){margin: 5% 5% 1% 5%;}
@include for-size(mobile-428){margin: 5% 5% 1% 5%;}
@include for-size(mobile-430){margin: 5% 5% 1% 5%;}
  &.mar-left-0{margin-left: 0;}
    }
    .mindful-section{
      display: flex;  
      padding: 15px 20px 0px 20px;
      flex-direction: column;
      .mindful-video-card{
        display: flex;
        flex-direction: row;
        padding: 20px;
        width: 100%;      
        margin: 0 0 30px 0;
        background: $videocardblue1;
        border-radius: 46px 0px;
        box-shadow: 0px 0px 10px $videocardblue1;

        @include for-size(mobile-360){padding: 9px; margin: 0 0 10px 0;}
        @include for-size(mobile-375){padding: 11px; margin: 0 0 10px 0;}
        @include for-size(mobile-390){padding: 15px; margin: 0 0 12px 0;}
        @include for-size(mobile-414){padding: 15px; margin: 0 0 10px 0;}
        @include for-size(mobile-428){padding: 15px; margin: 0 0 20px 0;}
        @include for-size(mobile-430){padding: 15px; margin: 0 0 20px 0;}

      &.cardblue2{ background: $videocardblue2; box-shadow: 0px 0px 10px $videocardblue2;}
      &.cardblue3{ background: $videocardblue3; box-shadow: 0px 0px 10px $videocardblue3;}
      &.cardblue4{ background: $videocardblue4; box-shadow: 0px 0px 10px $videocardblue4;    margin-top: 8px;
      
      }
        
      
  
        .card-img{
          padding: 0px;
          text-align: center;
          margin: 0 30px 0 0;
          width: 150px;
        @include for-size(mobile-360){margin: 0; padding: 5px 10px 0 5px; width: 128px;}
        @include for-size(mobile-375){margin: 0; padding: 5px 10px 0 5px; width: 128px;}
        @include for-size(mobile-390){margin: 0; width: 130px;}
        @include for-size(mobile-414){margin: 0; width: 140px;}
        @include for-size(mobile-428){margin: 0; width: 150px;}
        @include for-size(mobile-430){margin: 0; width: 150px;}

        img{width: 80%; float: left;
          @include for-size(mobile-360){width: 90%;}
          @include for-size(mobile-375){width: 90%;}
          @include for-size(mobile-390){width: 80%;}
          @include for-size(mobile-393){width: 80%;}
          @include for-size(mobile-414){width: 80%;}
          @include for-size(mobile-428){width: 80%;}
          @include for-size(mobile-430){width: 80%;}
        }
        }
  
.card-details{
display: flex;
flex-direction: column;
.card-heading{
font-family: $fontstyle-lato-bold;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 120.5%;
color: $text-black-color;
text-align: left;
width: 110px;
margin-bottom: 12px;
@include for-size(mobile-360){margin-bottom: 10px;}
@include for-size(mobile-375){margin-bottom: 10px;}
@include for-size(mobile-390){margin-bottom: 10px;}
@include for-size(mobile-414){margin-bottom: 5px;}
@include for-size(mobile-428){margin-bottom: 7px;}
@include for-size(mobile-430){margin-bottom: 7px;}
   }
.card-description{
font-family: $fontstyle-lato;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: $text-black-color;
text-align: left;
width: 122px;
}
}
}
.cardblue3 .card-details .card-heading{ margin-bottom: 5px;}
.cardblue4 .card-details .card-heading{ margin-bottom: 5px;}
@media only screen and (min-height: 892px){
  .mindful-video-card {
      margin: 0 0 20px 0;
}
}
}
  }

// Theraphy header
.thearapy-header{
  width: 100%; padding: 5%; display: flex; justify-content: space-between; position: fixed;
  .backup-btn{ width: 40px; }
  
 &.header-0{background-color: $videocardblue1;}
 &.header-1{background-color: $videocardblue2;}
 &.header-2{background-color: $videocardblue3;}
 &.header-3{background-color: $videocardblue4;}
 
 
 .thearapy-container{
   display: flex;
   .thearapy-icon{
     margin: 0 10px;
   }
   .thearapy-heading{
    font-family: $fontstyle-lato-bold;
 font-size: 18px;
 font-weight: 700;
 line-height: 22px;
 letter-spacing: 0em;
 text-align: left;
 color: $text-black-color;
 margin: 4px 0 0 0;
 
   }
 }
 }
 // Theraphy header
  // video-home
  
 // theraphy-home
 .theraphy {
  padding: 20% 5% 5% 5%;
  // background: linear-gradient(180deg, #fff 40%, #EBDBFE 40%);
  @include for-size(mobile-360){padding: 20% 5% 5% 5%;}
  @include for-size(mobile-375){padding: 21% 5% 5% 5%;}
  @include for-size(mobile-390){padding: 21% 5% 5% 5%;}
  @include for-size(mobile-393){padding: 20% 5% 5% 5%;}
  @include for-size(mobile-414){padding: 21% 5% 7% 5%;}
  @include for-size(mobile-428){padding: 21% 5% 4% 5%;}
  @include for-size(mobile-430){padding: 21% 5% 4% 5%;}
.music-img{ width: 100%; 
  @include for-size(mobile-360){margin: 0 0 1% 0;}
  @include for-size(mobile-375){margin: 0 0 4% 0;}
  @include for-size(mobile-390){margin: 0 0 2% 0;}
  @include for-size(mobile-393){margin: 0 0 5% 0;}
  @include for-size(mobile-414){margin: 0 0 4% 0;}
  @include for-size(mobile-428){margin: 0 0 4% 0;}
  @include for-size(mobile-430){margin: 0 0 15% 0;}
}
 }
 .theraphy-footer {
  padding:25px 5%;

  @include for-size(mobile-360){padding:18px 5%;}
  @include for-size(mobile-375){padding:25px 5%;}
  @include for-size(mobile-390){padding:25px 5%;}
  @include for-size(mobile-393){padding:25px 5%;}
  @include for-size(mobile-412){padding:25px 5%; margin: 15px 0 0 0;}
  @include for-size(mobile-414){padding:25px 5%; margin: 0;}
  @include for-size(mobile-428){padding:25px 5%;}
  @include for-size(mobile-430){padding:25px 5%;}
}
@media only screen and (min-height: 892px)
{ 
  .theraphy-footer {margin: 60px 0 0 0;}
}
.font-14{
  font-family: $fontstyle-lato;
  font-size:14px;
  line-height: 15px;
  margin: 0 0 0 10px;
 font-weight: 400;
 color: $text-black-color;
}
 .theraphy-footer p{
  font-family: $fontstyle-lato-medium;
  color: $headingtext;
  font-size: 14px;
  font-weight: 500;
  }
  .theraphy-footer p span {
    font-weight: 700;
}
.theraphy-footer .partner-width{ width: 76%; margin:10px auto 0 auto;}
.theraphy-footer .partner-text{
  font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color:$header-topbg-color;
    font-family: $fontstyle-lato;
    margin: 0 10px 0 0;
}
  .progress-bar{
    background-color:$header-topbg-color !important;
  }
  .media-play{
    background-color:$header-topbg-color;
    //filter: drop-shadow(0px 0px 10px #BB86FC);
  }

  // emotion btn
.emotion-bg{
  background-color: $whitecolor; height: 100%;
  // Rating Slider
.rating-slider{
  padding: 35% 6% 0% 6%;
  text-align: center;
  height: 82%;
  line-height: normal;
  .rangelabel{ display: flex; margin-top: 30px;
  p{
    color: $text-black-color; 
    font-family: $fontstyle-lato;
    font-size: 16px;  
    font-weight: 400;
    text-align: left;
   
  }
  :last-child{text-align: right;}
  }
  input[type="range"]::-webkit-slider-thumb {
    background: #6200EE !important; 
    box-shadow: 0px 1px 8px 0px #6200EE !important;}
  /* All the same stuff for Firefox */
   input[type="range"]::-moz-range-thumb {
    background: #6200EE!important;
    box-shadow: 0px 1px 8px 0px #6200EE !important;}
  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb { 
    background: #6200EE !important;
    box-shadow: 0px 1px 8px 0px #6200EE !important;}

  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
    top: 7px !important;
  }
  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
    top: 0px !important;
}

  p{
    color: $text-black-color; 
    font-family: $fontstyle-lato;
    font-size: 24px;  
    font-weight: 400;
    width: 60%;
    text-align: left;
  }

  .rating-btn{
    background: $introbtn;  
    border-color: transparent;
    border-radius: 30px;    
    color: $text-black-color;
    font-family: $fontstyle-lato;
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 40px; 
    text-align: center; 
    width: 97%;
    margin: 40px auto;
   text-transform: capitalize;
  }

  .range-slider__wrap{ padding: 0; margin-bottom: 49%;}
  
}
.emotion-section{
  padding: 35% 6% 27% 6%;
  height: 75%;
  p{
    color: $text-black-color; 
    font-family: $fontstyle-lato; 
    font-size: 24px;  
    font-weight: 400;
    width: 60%;
    margin-bottom: 40px;
    line-height: normal;
}

  .morbtn{
    border: 1px solid $mor-btnborder; 
    border-radius: 56px;  
    background-color: $whitecolor;
    height: 40px; 
    color: $btntext-color; 
    font-family: $fontstyle-lato-medium; 
    font-size: 14px;  
    font-style: normal;
    font-weight: 500;
    margin: 5px;
    box-shadow: 0 0 10px $ligth-btn-color;
    padding: 0 17px;
    @include for-size(mobile-360){margin: 5px 13px 15px 0;}
    @include for-size(mobile-375){margin: 5px 12px 15px 0;}
    @include for-size(mobile-390){margin: 5px 15px 15px 0;}
    @include for-size(mobile-393){margin: 5px 18px 15px 0;}
    @include for-size(mobile-412){margin: 5px 23px 15px 0;}
    @include for-size(mobile-414){margin: 5px 20px 15px 0;}
    @include for-size(mobile-428){margin: 5px 25px 15px 0;}
    @include for-size(mobile-430){margin: 5px 25px 15px 0;}
     
    }
    .morbtn.active{background: $ligth-btn-color;}
    .evnbtn{border: 1px solid $header-topbg-color; 
      @extend .morbtn; box-shadow: 0 0 10px $lightbluebtn;}
    .evnbtn.active{background: $header-topbg-color; font-family: $fontstyle-lato-bold;  color: $text-white-color; font-weight: 700;} 
  }
  .emotion-btn{
    background: $introbtn;  
    border-color: transparent;
    border-radius: 30px; 
    box-shadow: 0 0 10px $introbtn; 
    color: $text-black-color;
    font-family: $fontstyle-lato-bold; 
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 40px; 
    text-align: center; 
    width: 90%;
    margin: 0 auto;
    position: absolute;
    bottom: 15%;   
    left: 5%;
  }
}

// emotion btn

.form-group{
  margin-bottom: 0 !important;
}

// Desktop Landing Page
.qr-app-container{
  width: 100%;
  background-color: $whitecolor;
  padding: 10% 0 4% 0;
.qr-app{
  display: flex;
  justify-content: space-between;
   margin:  0 auto;
   width: 70%;
  

  .qr-container{
    width: 63%;

    .qr-heading{
      color: $text-black-color;
      font-family: $fontstyle-lato-bold; 
      font-size: 55px; 
      font-style: normal; 
      font-weight: 700;
      line-height: 50px;
    }
    p{
      padding: 40px 0;
      font-family: $fontstyle-lato; 
      font-size: 30px; 
      font-style: normal; 
      font-weight: 400;
      line-height: 40px;
    }
    .qr-img{
      img{width: 25%;}
    }
  }
  .mobile-container{
    text-align: right;
    img{width: 70%;}
  }
}
}
.landing-card{width: 100%; padding: 40px auto; background-color:$slickdotbg;
  .landing-card-heading{
    font-family: $fontstyle-lato-bold; 
      font-size: 16px; 
      font-style: normal; 
      font-weight: 700;
      color: $text-black-color;
      text-align: center;
      padding: 20px 0 5px 0;
  }
  .card-container{
    display: flex;
    width: 70%;
    margin: 0 auto;
    padding: 20px 0;
    justify-content: center;
.desktop-card{
  width:30%;
  padding: 2%;
  background-color: $whitecolor;
  margin-right: 3%;
  border-end-end-radius: 50px;
    border-start-start-radius: 50px;
  .desktop-card-img{ display: none;
    img{width: 100%;}
  }
  .desktop-card-img-blue{ img{width: 100%;}}
  .card-titile{
    font-family: $fontstyle-lato-medium; 
      font-size: 14px; 
      font-style: normal; 
      font-weight: 500;
      color: $header-topbg-color;
      margin: 10px 0;
      
  }
  p{
    font-family: $fontstyle-lato; 
    font-size: 10px; 
    font-style: normal; 
    font-weight: 400;
    color: $text-black-color;
    line-height: 13px;
    
}
}
}
}
// Desktop Landing Page
.subtitles{
  display: flex;
  justify-content: right;
  margin: 10px 0 13px 0;

  .subtitles-btn{ 
    /*border:2px solid $header-topbg-color; */
     padding: 0;    
    margin-right: 0;
    text-align: right;     
    width: -webkit-fill-available;}
    .subtitles-btn.active{
      padding: 0;
      margin-right: 0;
      width: -webkit-fill-available;
    }
}
@media only screen and (min-height: 892px)
{ 
  .audio-cc
  {height: 30px; width: 100%; display: flex;}
}

.play-control{
  display: flex;
  align-items: center;
  justify-content:center;
   margin: 15px 0 0 0;
   @include for-size(mobile-360){margin:10px 0 0 0;}
   @include for-size(mobile-375){margin: 20px 0 0 0;}
   @include for-size(mobile-390){margin: 15px 0 0 0;}
   @include for-size(mobile-393){margin: 18px 0 0 0;}
   @include for-size(mobile-414){margin: 15px 0 0 0;}
   @include for-size(mobile-428){margin: 35px 0 0 0;}
  @include for-size(mobile-430){margin: 15px 0 0 0;}
  img{
  margin:0 !important;
  //box-shadow:  0px 2px 10px rgba(0, 0, 0, 0.25);
}
}
@media only screen and (min-width: 393px) and (min-height: 727px)
{.play-control {margin: 25px 0 !important;}}

@media only screen and (min-height: 892px)
{ 
  .play-control {margin: 26px 0;}
}

  // Lotus blue theme
}

// emotion blue theme model
.emotionModel{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .modal-dialog{
    top: 50%;
    left:47.5%;
    transform: translate(-50%, -50%) !important;
  .modal-content {
    width: 90%;
    background-color: $header-topbg-color !important;
    border-end-end-radius: 50px !important;
    border-start-start-radius: 50px !important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    padding: 10px 15px !important;
    margin: 0px auto !important;
    justify-content: space-between !important;
   
    
    .text-color-danger{ color: $whitecolor;}
    .modal-header{
     border-bottom: none;
    .heading-text-title-popup{
      font-family: $fontstyle-lato-bold;
      font-size: 16px;
    color: $whitecolor;
    font-weight: 700;
    margin: 0;
    padding-top: 0;
    margin-top: 20px;
    
    }
  }
}
    .pop-button-ohimpact{
      background: $introbtn;
      color:$btntext-color;
      font-weight: 700;      
      border-radius: 20px;
      box-shadow:  0px 0px 10px rgba(212, 177, 255, 1);
      margin: 20px 0 0 0;
    }
    

   .btn-close {
      // background: url(../../../../../assets/images/images-v3/close-white-icon.svg) no-repeat;
      position: absolute;
      right: 0px;
      top: 5px;
       opacity: 10;
       width: 40px;   
       background: none;
     }
     .btn-close:before, .btn-close:after {
       position: absolute;
       left: 15px;
       content: ' ';
       height: 35px;
       width: 4px;
       background-color: #ffffff;
       border-radius: 20px;
     }
    .btn-close:before {
       transform: rotate(45deg);
     }
    .btn-close:after {
       transform: rotate(-45deg);
     }
}

}
// emotion blue theme model
.form-check-label a {
  color: #000000 !important;
  font-weight: 700;
  font-size: 12px;
  font-family: $fontstyle-lato-bold;
}

.volta-emailcapturemodel-blue .modal-body .form-group label{
  color: #000;
  font-weight: 700;
}
// slider range

.progressBar-blue {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  background-color: $videocardblue1; 
 



#scrubSlider {
  width: 100%;
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  background: none;
  margin: 7px 10px 0 0;
  }

.scrubSlider::-webkit-slider-runnable-track {
  width: 100%; 
  border-radius: 12px; 
}

// new Range slider
.range-slider {  
  align-items: center;
  width: 100%;
  height: 8px;
  overflow: hidden;
  border-radius: 16px;
   margin: 15px 0;
 }

/* Track: webkit browsers */
.range-slider input[type="range"]::-webkit-slider-runnable-track { 
  background: #000!important;
}


/* Thumb: webkit */
.range-slider input[type="range"]::-webkit-slider-thumb {   
  border-radius: 50%; 
  /*  slider progress trick  */
  //box-shadow: -407px 0 0 400px #f50;  
  box-shadow: -407px 0 0 400px $header-topbg-color;  

}


input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  /*  overflow: hidden;  remove this line*/  
  /* New additions */
  height: 10px;
 
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */  
  background-color: $header-topbg-color;
  border-radius: 50%;
  border: none;
  height: 19px !important;
  width: 19px !important;
  //background: url("../../../../../assets/images/images-v3/blue-range-circle.svg")no-repeat;
  // filter: drop-shadow(0px 0px 10px #BB86FC) !important;
 /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}

}

video::-webkit-media-text-track-display-backdrop {
  /*background-color: #000 !important;
  overflow: visible !important;
  color: #fff;*/
}
.screenMSG{  
  width: 100%;
  margin: 0;
  font-family: $fontstyle-lato; 
  font-size: 30px; 
  font-style: normal; 
  font-weight: 700;
  color: $header-topbg-color;
  background-color: #F7F9EE;
  height: 100vh;
    padding: 18% 25%;

}