@import "./_mediasize.scss";

//Top header
.header-bg{ 
    background-color: $header-topbg-color; 
    position: fixed; 
    height: 88px; 
    width: 100%; 
    z-index: 99;
  .curvedesign-bg-color-white{background-color: white;position: relative;top: -9px; }
  .curvedesign-bg-color-landing{background-color: $landingbg;position: relative;top: 0px;}
  .top-nav{ width:96%; 
    display: flex;  
    justify-content: center; 
    text-align: center; 
    padding: 28px 2%;
  .logo img{
      width: 40%;      
      @media only screen and (max-width: 1920px) and (min-width: 500px)  { width: 13%;}
    }
    .nav-back{width: 40%; display: inline-grid;}
  }
  }
  .header-mobileheight{height: 88px !important;}
  .curvedesign{ 
    width:100%; 
    border-top-left-radius: 34px; 
    height: 40px;
    display: none;    
  }
  .curve1{
    position: fixed;
    top: 87px;
    left: -1px;
    z-index: 100;
    width: 60px;
    height: 61px;
    background: url(../../images/top-corner-curve.svg) no-repeat; 
     
  }