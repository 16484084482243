

.volta-model-blue::before {
    content: "";      
    display: inline-block;
    height: 100%;    
    margin-right: -4px;
    vertical-align: middle;
}
.volta-model-blue {
  text-align: center;
.modal-dialog { 
    display: inline-block;  
    text-align: left;   
    vertical-align: middle;
    padding: 10px;
}

  .model-heading{
  color: $header-topbg-color!important; 
  font-family: $fontstyle-lato;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120.5%;
   padding-top: 40px; 
   text-align: left; 
   }
  .modal-content{
    background-color: $landingbtn-color!important; 
    border-end-end-radius: 50px!important;
    border-start-start-radius: 50px!important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    padding: 10px 15px 30px 15px!important; 
    margin: 20px 0!important;
    justify-content: space-between!important; 
    }
  .modal-header .modal-title{@extend .model-heading; }
  .modal-body{text-align: center; padding:5px 10px!important;
   
  P{ 
    color: $text-black-color;   
    font-family: $fontstyle-lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120.5%;
    margin: 4% 0;
    text-align: left;

  }
 
  
  .feedback-font-style{
    font-family: $fontstyle-lato;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $header-topbg-color;
    }
  img{width: 70%; padding:0;

    @include for-size(mobile-360){width: 52%;}
  @include for-size(mobile-375){width: 60%;}
  @include for-size(mobile-390){width: 60%;}
  @include for-size(mobile-414){width: 70%;}
  @include for-size(mobile-428){width: 70%;}
  @include for-size(mobile-430){width: 70%;}
  
  }
  }
  .modal-header{
    padding: 0 .5rem !important;     margin: 0; 
    border: none!important;
  }
  .modal-header .btn-close {
    // background: url(../../images/images-v3/close-blue.svg) no-repeat;
    position: absolute;
    right: 0px;
    top: 4px;
     opacity: 10;
     width: 40px;   
     background: none;
   }
   .modal-header .btn-close:before, .modal-header .btn-close:after {
     position: absolute;
     left: 15px;
     content: ' ';
     height: 35px;
     width: 4px;
     background-color: #6200EE;
     border-radius: 20px;
   }
   .modal-header .btn-close:before {
     transform: rotate(45deg);
   }
   .modal-header .btn-close:after {
     transform: rotate(-45deg);
   }
.tapShare{ 
  display: flex;
  .tapshare-text{
    font-family: $fontstyle-lato-bold;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $text-black-color;}
  p{ margin: .5rem 1rem .5rem 1rem;  
    @extend .tapshare-text;
  }
  .add-heading{    
    @extend .tapshare-text;
    margin-bottom: .5rem;
  }
img{width: 6%;}
}
.pop-btn{
   
        background: $introbtn;  
        border-color: transparent;
          border-radius: 30px; 
          box-shadow: 0 0 10px $introbtn; 
          color: $text-black-color;
          font-family: $fontstyle-lato; 
          font-size: 15px; 
          font-style: normal; 
          font-weight: 700;
          height: 40px; 
          text-align: center; 
          width: 100%;
      &.btnwidth95{width: 95%; margin:0 auto;}
    }
}
// Model Popup blue

// Model Popup green

.volta-model-green::before {
  content: "";      
  display: inline-block;
  height: 100%;    
  margin-right: -4px;
  vertical-align: middle;
}
.volta-model-green {
  text-align: center;
  top:17px !important;
.modal-dialog { 
  display: inline-block;  
  text-align: left;   
  vertical-align: middle;
  padding: 10px;
}

.model-heading{
color: $text-white-color!important; 
font-family: $fontstyle-diodrum-bold;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 120.5%;
 padding-top: 40px; 
 text-align: left; 
 }
.modal-content{  
  padding: 0!important; 
  margin: 20px 0!important;
  justify-content: space-between!important; 
  
  .modal-content-green{
    background-color:#F7F6E9!important;
    padding: 0 0 10px 0; 
    text-align: center;   
    
    .body-content-green{
      position: relative;      
      padding: 10px 15px 10px 15px!important;
      background: url("../../images/images-v3/gel-bg.png")no-repeat #488F45;
  background-position: bottom;
  width: 100%;    
  background-position-y: 99px;
  margin-bottom: 20px;
  top:-1px; overflow: hidden;
  border-radius: 0% 0% 100% 100%/0% 0% 8% 8%;
  height: 371px;
  @include for-size(mobile-360){ height: 340px; background-position-y: 51px;}
  @include for-size(mobile-375){ height: 371px; background-position-y: 99px;}
  @include for-size(mobile-390){height: 371px; background-position-y: 99px;}
  @include for-size(mobile-414){height: 371px; background-position-y: 99px;}
  @include for-size(mobile-428){height: 371px; background-position-y: 99px;}
  @include for-size(mobile-430){height: 371px; background-position-y: 99px;}


  img{
    position: absolute; 
    top: 150%;
    left: 0%;
    width: 100%;}
    }
  }
  }
.modal-header .modal-title{@extend .model-heading; }
.modal-body{text-align: center; padding:5px 10px!important;
P{ 
  color: $text-white-color;   
  font-family: $fontstyle-diodrum;
  font-style: normal;
  font-size: 14px;
  line-height: 120.5%;
  margin: 4% 0;
  text-align: left;
 
}

.feedback-font-style{
  font-family: $fontstyle-diodrum;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: $header-topbg-color;
  }
img{width: 70%; padding:0;}
}
.modal-header{
  padding: 0 .5rem !important; 
  margin: 0 0 20px 0; 
  border: none!important;
}
.modal-header .btn-close {
  // background: url(../../images/images-v3/close-icon.svg) no-repeat;
  position: absolute;
  right: 0px;
  top: 4px;
   opacity: 10;
   width: 40px;   
   background: none;
 }
 .modal-header .btn-close:before, .modal-header .btn-close:after {
   position: absolute;
   left: 15px;
   content: ' ';
   height: 35px;
   width: 4px;
   background-color: #031C64;
   border-radius: 20px;
 }
 .modal-header .btn-close:before {
   transform: rotate(45deg);
 }
 .modal-header .btn-close:after {
   transform: rotate(-45deg);
 }
.tapShare{ 
display: flex;
width: 80%;
margin: 0 auto;
.tapshare-text{
  font-family: $fontstyle-diodrum-bold;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #031C64;}
p{ margin: .5rem 1rem .5rem 1rem;  
  @extend .tapshare-text;
}
.add-heading{    
  @extend .tapshare-text;
  font-family: $fontstyle-diodrum; 
  margin-bottom: .5rem;
  color: $text-black-color !important;
}
img{width: 6%;}
}
.pop-btn{
 
      background: $addshortcutbtn;  
      border-color: transparent;
        border-radius: 30px; 
        box-shadow: 0 0 10px $addshortcutbtn; 
        color: $text-white-color;
        font-family: $fontstyle-diodrum;
        font-size: 15px; 
        font-style: normal; 
        font-weight: 700;
        height: 40px; 
        text-align: center; 
        width: 100%;
        text-transform: uppercase;
    &.btnwidth95{width: 95%; margin:0 auto;}
  }
}


// Email green theme Capture model


.volta-emailcapturemodel-green::before {
  content: "";      
  display: inline-block;
  height: 100%;    
  margin-right: -4px;
  vertical-align: middle;
}
.volta-emailcapturemodel-green {
  text-align: center;
  top:17px !important;
  .modal-dialog { 
    display: inline-block;  
    text-align: left;   
    vertical-align: middle;
    padding: 10px;
  }
  
  .model-heading{
  color: $introbtn !important; 
  font-family: $fontstyle-diodrum;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120.5%;
   padding:0; 
   text-align: left; 
   }
  .modal-content{  
    padding:15px!important; 
    margin: 20px 0!important;
    justify-content: space-between!important; 
    border-end-end-radius: 50px!important;
    border-start-start-radius: 50px!important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    background-color:#F7F6E9!important;
    @include for-size(mobile-360){ padding: 10px!important;}
  @include for-size(mobile-375){ padding: 15px!important;}
  @include for-size(mobile-390){padding: 25px!important;}
  @include for-size(mobile-393){padding: 25px!important;}
  @include for-size(mobile-412){padding: 25px!important;}
  @include for-size(mobile-414){padding: 25px!important;}
  @include for-size(mobile-428){padding: 25px!important;}
  @include for-size(mobile-430){padding: 25px!important;}

    .modal-content-green{
      background-color:#F7F6E9!important;
      padding: 0 0 10px 0; 
      text-align: center;   
      
    }
    }
  .modal-header .modal-title{@extend .model-heading; padding: 19px 0 10px 0; color: $emailheading !important; width: 80%;
    font-family: $fontstyle-diodrum-medium;  }
   
    
  .modal-body{text-align: center; padding:5px 10px!important;
  P{ 
    color: $text-black-color;   
    font-family: $fontstyle-diodrum;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120.5%;
    margin: 0 0 10% 0;
    text-align: left; 
    &.error-massage{
      font-family: $fontstyle-diodrum-bold;
      width: 100%;
      float: left;
      text-align: left;
      font-size: 14px;
      color: #ED7738 !important;
      padding:0 10px 0 0%;
      margin-bottom: 10px;
      font-weight: 700 !important;
      }
    
  }
  .feedback-font-style{
    font-family: $fontstyle-diodrum-medium;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $header-topbg-color;
    }
  img{width: 70%; padding:0;}
  .form-group label {
    float: left;
    margin-bottom: 8px;
    font-family: $fontstyle-diodrum-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #031C64;
    text-transform: uppercase;
}
  }
  .modal-header{
    padding: 0 .5rem !important; 
    margin: 0; 
    border: none!important;
  }
  .modal-header .btn-close {
   // background: url(../../images/images-v3/close-icon.svg) no-repeat;
   position: absolute;
   right: 0px;
   top: 4px;
    opacity: 10;
    width: 40px;   
    background: none;
  }
  .modal-header .btn-close:before, .modal-header .btn-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 35px;
    width: 4px;
    background-color: #031C64;
    border-radius: 20px;
  }
  .modal-header .btn-close:before {
    transform: rotate(45deg);
  }
  .modal-header .btn-close:after {
    transform: rotate(-45deg);
  }
  
 
  .form-control{
    border: 1.5px solid #ED7738;
    box-shadow: 0px 0px 5px #ED7738;
    border-radius: 9px;
    line-height: 1.9;
  }
  .form-check-input{
    margin-left: -1.5em !important;
    border: 1.5px solid #ED7738;
    box-shadow: 0px 0px 5px #ED7738;
  }
 
  .form-check .form-check-label {
    font-family: $fontstyle-diodrum-medium;
  }
  .pop-btn{
   
        background: $addshortcutbtn;  
        border-color: transparent;
          border-radius: 30px; 
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25); 
          color: $text-white-color;
          font-family: $fontstyle-diodrum-bold;
          font-size: 15px; 
          font-style: normal; 
          font-weight: 700;
          height: 40px; 
          text-align: center; 
          width: 100%;
          text-transform: uppercase;
          margin-top: 20px;
         
      &.btnwidth95{width: 95%; margin:0 auto;}

    }
    a{
      font-family: $fontstyle-diodrum-medium;
      font-weight: 700;
      }
    .pop-feedback-button{ 
      background-color: #ED7738;     
      font-family: $fontstyle-diodrum-medium ;
      border:none;
      font-style: normal;   
      font-size: 14px;  
      line-height: 20px; 
      text-align: center; 
      padding: 8px 38px; 
      margin-bottom: 20px; 
      margin-right: 11px;         
      white-space: nowrap;
      border-radius: 20px;     
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
      color: $whitecolor;
      font-weight: 500;
    }
    
      .pop-feedback-button.active{
      background-color:#ED7738;
      color: $whitecolor;
      box-shadow: 0px 0px 10px #ED7738;
      font-weight: 700;
      
      }
      .addhometitle{
        color: $whitecolor !important; 
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120.5%;  
         text-align: left;  padding: 0;  width: 80%;
        font-family: $fontstyle-diodrum-bold;}
  }

// Email green theme Capture model

// Email blue theme Capture model

.volta-emailcapturemodel-blue::before {
  content: "";      
  display: inline-block;
  height: 100%;    
  margin-right: -4px;
  vertical-align: middle;
}
.volta-emailcapturemodel-blue {
  text-align: center;
  top:17px !important;
  .modal-dialog { 
    display: inline-block;  
    text-align: left;   
    vertical-align: middle;
    padding: 10px;
  }
  
  .model-heading{
  color: $introbtn !important; 
  font-family:$fontstyle-lato; 
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120.5%;
   padding-top: 20px; 
   text-align: left; 
   }
  .modal-content{  
    padding: 15px!important; 
    margin: 20px 0!important;
    justify-content: space-between!important; 
    border-end-end-radius: 50px!important;
    border-start-start-radius: 50px!important;
    border-top-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    background-color:#F7F6E9!important;

  @include for-size(mobile-360){ padding: 13px!important;}
  @include for-size(mobile-375){ padding: 14px!important;}
  @include for-size(mobile-390){padding: 25px!important;}
  @include for-size(mobile-393){padding: 25px!important;}
  @include for-size(mobile-412){padding: 25px!important;}
  @include for-size(mobile-414){padding: 25px!important;}
  @include for-size(mobile-428){padding: 25px!important;}
  @include for-size(mobile-430){padding: 25px!important;}

     #menu-item-41 span.ubermenu-sub-indicator-close{  top: auto !important;
    height: 30px !important;
    left: auto !important;
    right: 35px;
    position: absolute;
    bottom: 17px;
  } 
    .modal-content-green{
      background-color:#F7F6E9!important;
      padding: 0 0 10px 0; 
      text-align: center;   
      
    }
    }
  .modal-header .modal-title{@extend .model-heading; padding: 19px 0 15px 0;  color: #6200EE !important; width: 78%;  }
  .modal-body{text-align: center; padding:5px 10px!important;
  P{ 
    color: $text-black-color;  
    font-family: $fontstyle-lato; 
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120.5%;
    margin: 0 0 6% 0;
    text-align: left;

    &.error-massage{
      font-family: $fontstyle-lato;
      width: 100%;
      float: left;
      text-align: left;
      font-size: 14px;
      color: $header-topbg-color !important;
      padding:0 10px 0 0%;
      margin-bottom: 10px;
      font-weight: 700 !important;
      }
  }
  .feedback-font-style{
    font-family: $fontstyle-lato; 
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $header-topbg-color;
    }
  img{width: 70%; padding:0;}
  .form-group label {
    float: left;
    margin-bottom: 8px;
    font-family: $fontstyle-lato-bold; 
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #031C64;
    text-transform: uppercase;
}
  }
  .modal-header{
    padding: 0 .5rem !important; 
    margin: 0; 
    border: none!important;
  }
  .modal-header .btn-close {
    // background: url(../../images/images-v3/cross-icon.svg) no-repeat;
    position: absolute;
    right: 0px;
    top: 4px;
     opacity: 10;
     width: 40px;   
     background: none;
   }
   .modal-header .btn-close:before, .modal-header .btn-close:after {
     position: absolute;
     left: 15px;
     content: ' ';
     height: 35px;
     width: 4px;
     background-color: #6200EE;
     border-radius: 20px;
   }
   .modal-header .btn-close:before {
     transform: rotate(45deg);
   }
   .modal-header .btn-close:after {
     transform: rotate(-45deg);
   }
 
  .form-control{
    border: 1.5px solid $introbtn;
    box-shadow: 0px 0px 5px $introbtn;
    border-radius: 9px;
    line-height: 1.9;
  }
  .form-check-input {
    border: 1.5px solid $introbtn;
    box-shadow: 0px 0px 5px $introbtn;
  }
  .form-check .form-check-label {
    font-family: $fontstyle-lato; 
  }
  .pop-feedback-button{ 
    background-color: $modelfeedbackbtn;  
    border-radius: 50px; 
    border: 1px solid $lightbluebtn; 
    font-family: $fontstyle-lato-medium;
    font-style: normal;   
    font-size: 14px;  
    line-height: 20px; 
    text-align: center; 
    padding: 8px 38px; 
    margin-bottom: 20px; 
    margin-right: 11px; 
    white-space: nowrap;
    box-shadow: 0px 0px 5px $lightbluebtn;
    color: $text-black-color;
    font-weight: 500;
  }
 /* .pop-feedback-button:hover{
    background-color: $lightbluebtn; 
    color: $text-black-color;
    box-shadow: 0px 0px 5px $lightbluebtn;
  }*/
  .pop-feedback-button.active{
    background-color:$lightbluebtn; 
    color: $whitecolor;
    box-shadow: 0px 0px 10px $lightbluebtn;
    font-family: $fontstyle-lato-bold;
    font-weight: 700;
    }
  .pop-btn{
   
        background: $introbtn;  
        border-color: transparent;
          border-radius: 30px; 
          box-shadow: 0px 0px 10px 0px $introbtn;
          color: $text-black-color;
          font-family: $fontstyle-lato-bold; 
          font-size: 15px; 
          font-style: normal; 
          font-weight: 700;
          height: 40px; 
          text-align: center; 
          width: 100%;
          text-transform: capitalize;
          margin-top: 10px;
      &.btnwidth95{width: 95%; margin:0 auto;}
    }
    .addhometitle{
      color: $whitecolor !important; 
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120.5%;  
       text-align: left;  padding: 0;  width: 80%;
      font-family: $fontstyle-diodrum-bold;}
  }
  
// Email blue theme Capture model
// Model popup Green


