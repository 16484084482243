 // model popup

.brushingModel::before {
    content: "";      
    display: inline-block;
    height: 100%;    
    margin-right: -4px;
    vertical-align: middle;
}
.brushingModel {
  text-align: center;
.modal-dialog { 
    display: inline-block;  
    text-align: left;   
    vertical-align: middle;
    padding: 10px;
}

  .model-heading{
  color: $header-topbg-color!important; 
  font-family: $fontstyle-allenoire;
   font-size: 24px; 
   font-style: normal;
   font-weight: 400;
   line-height: 120.5%;
   margin-bottom: 0.5rem;
   padding-top: 40px; 
   text-align: left; 
   }
  .modal-content{
    background-color: $landingbg!important; 
    border-end-end-radius: 50px!important;
    border-start-start-radius: 50px!important;
    padding: 10px 15px!important; 
    margin: 20px 0!important;
    justify-content: space-between!important; 
    }
  .modal-header .modal-title{@extend .model-heading; }
  .modal-body{text-align: center; padding:5px 10px!important;
  P{ 
    font-size: 14px;  
    color: $text-black-color;   
    font-family: $fontstyle-lato;
    font-style: normal; 
    font-weight: 400; 
    line-height: 17px;  
    text-align: left; 
    margin-bottom: 10px;
  }
  .feedback-font-style{
    font-family: $fontstyle-lato;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $header-topbg-color;
    }
  img{width: 70%; padding:0;}
  }
  .modal-header{
    padding: 0 .5rem !important; 
    margin: 0; 
    border: none!important;
  }
  .modal-header .btn-close {
    background: url(../../images/cross-icon.svg) no-repeat;
    background-size: 80; 
    position: absolute; right: 0px;  
    top: 20px; opacity: .9;  
    width: 30px; 
}
.tapShare{ 
  display: flex;
  .tapshare-text{
    font-family: $fontstyle-lato;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: $text-black-color;}
  p{ margin: .5rem 1rem .5rem 1rem;  
    @extend .tapshare-text;
  }
  .add-heading{    
    @extend .tapshare-text;
    margin-bottom: .5rem;
  }
img{width: 8%;}
}
}
// Model Popup