@import "./default-color";
@import "../../../../../assets/css/common/mediasize-v3";
@import "../../../../../assets/css/common/fontstyle";

html, body{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: $fontstyle-diodrum;
  
}
.pm0{ padding: 0; margin: 0;}
.pd-lr{padding: 0;}



// typography
.text-heading{
    font-family: $fontstyle-diodrum-bold;
    font-style: normal;
    font-weight: 700;
    font-size:12vw;
    line-height: 120.5%;
    @include for-size(mobile-360){ line-height: 110%;}
    @include for-size(mobile-430){ font-size:11vw;}

}
.home-text-balck{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120.5%;
    font-family: $fontstyle-diodrum-medium;

}
.container{ @extend .pm0; height: 100%; --bs-gutter-x: 0 !important;}
.btn-center{margin: 5px 0;}
.btn-center a{font-family: $fontstyle-diodrum-bold !important;
font-size: 12px !important; color: $text-black-color !important;}

.text-color-danger {
  width: 100%;
  float: left;
  text-align: left;
  color: #FF0000 !important;
  padding:0 10px;
}

.text-bold{font-weight: bold !important; }
.lotus-green{  @extend .pm0; 
  
  // left menu
.nav-menu{ background: $slickdotbg !important;z-index: 9998;left: -100%; }
.nav-menu.active{left: 0 !important; transition: 350ms;}
.nav-menu ul {
  padding: 0;
  margin: 80px 0 0 0 !important;
}
.nav-text a{ color: $text-black-color !important;}
.nav-text a:hover{ background: $menuhover!important;}
// left menu
.bg-landing{
//header
.header{
  background-color: $slickdotbg; 
  position: fixed; 
  z-index: 9999; 
  width: 100%; 
  padding: 9px 0;
  height:63px;

  img{width: 46%;}
  
 
  .back-button{
    background: url("../../../../../assets/images/images-v3/green-back-btn.svg")no-repeat;
    width: 42px;
    margin: 10px 0 0 17px;
 
  }

}
.header-sub{font-size: 2rem;}
.header-therapy{font-size: 2rem;}
//header end
.desktop-header{background-color: $slickdotbg; width:100%; text-align: center; 
  padding: 8px 0; position: fixed; height: 70px;}

//mid container
.img-border{border: 1px solid #ccc; border-radius: 50%; padding: 15px;}
.img-border2{border: 1px solid #ccc; border-radius: 50%; padding: 15px;}
.bg-inner-page{
  background-color: white !important;
}
.bg-desktop-page{
  background-color: $slickdotbg !important;
}
.home-mid-container{ 
  padding: 77px 0 0 0;
background-color: #F7F6E9;

  @include for-size(mobile-360){ padding: 50px 0 0 0;}
  @include for-size(mobile-375){ padding: 77px 0 0 0;}
  @include for-size(mobile-390){ padding: 77px 0 0 0;}
  @include for-size(mobile-414){ padding: 77px 0 0 0;}
  @include for-size(mobile-428){ padding: 77px 0 0 0;}

 .home-content{ top: 78px; height: 44vh;
  @include for-size(mobile-360){top: 14px; height:22vh;}
  @include for-size(mobile-375){top: 14px; height: 24vh}
  @include for-size(mobile-390){top: -10px;height: 22vh;}
  @include for-size(mobile-414){top: 20px;}
  @include for-size(mobile-428){top: 20px;}

.home-circle-img1{left: -8%; top:100px; @extend .img-border2;
  @include for-size(mobile-360){top: 48px;}
  @include for-size(mobile-375){top: 70px;}

img{width: 110px;
  @include for-size(mobile-360){width: 100px;}
}
}
.home-circle-img2{left: 73%;  @extend .img-border;

  img{
  @include for-size(mobile-360){width: 100px;}
}
}
.home-heading{color: $text-color; @extend .text-heading; width: 95%;
    left: 3%;
    top: 50px;
    text-align: center;
    @include for-size(mobile-360){ top: 10px;}
    @include for-size(mobile-375){ top: 0px;}
    @include for-size(mobile-390){  top: 50px;}
    @include for-size(mobile-414){  top: 50px;}
    @include for-size(mobile-428){  top: 50px;}

    @include for-size(mobile-360){left: 3%;
      text-align: center;
      font-size: 10vw;
      right: 5%;
      top: 30px;
     }
}
}
@media only screen and (min-width: 375px) and (min-height: 548px) 
and (-webkit-device-pixel-ratio: 2) {  
  .home-body-content p {margin: -3% auto !important;} 
}
@media only screen and (min-width: 414px) and (min-height: 719px) 
and (-webkit-device-pixel-ratio: 2) {  
  .home-body-content p {margin: 1% auto !important;} 
}


.home-body-content{
 
  p{  @extend .home-text-balck; 
    color: $text-black-color; 
    text-align: center; 
    margin: 10% auto; 
width: 88%;
    @include for-size(mobile-360){margin: 1% auto 4% auto;width: 92%;}
    @include for-size(mobile-375){margin: -9% auto 5% auto;}
    @include for-size(mobile-390){margin: 6% auto;}
    @include for-size(mobile-414){margin: 7% auto;}
    @include for-size(mobile-428){margin: 8% auto;} 
  
  } 
}

.gel-bg{ 
  background: url("../../../../../assets/images/images-v3/gel-bg.svg")no-repeat;
  background-position: bottom;
  width: 100%;    
  padding: 15% 0 3% 0;
  background-position-x: 0px;
  background-size: 100%;
  @include for-size(mobile-360){ padding: 15% 0 3% 0;}
  @include for-size(mobile-375){ padding: 15% 0 3% 0;}
  @include for-size(mobile-390){ padding: 5% 0 3% 0;}
  @include for-size(mobile-393){ padding: 16% 0 3% 0;}
  @include for-size(mobile-412){ padding: 5% 0 3% 0;}
  @include for-size(mobile-414){ padding: 15% 0 3% 0;}
  @include for-size(mobile-428){ padding: 9% 0 3% 0;} 
  @include for-size(mobile-430){ padding: 15% 0 3% 0;}
   }
   
   @media only screen and (min-height: 932px)
{ .gel-bg
  {padding: 9% 0 3% 0;} }
  @media only screen and (min-width: 412px) and (min-height: 746px)
  {  
    .gel-bg
     {padding: 17% 0 10% 0;}
   }
   @media only screen and (min-width: 428px) and (min-height: 746px)
   {  
     .gel-bg
      {padding: 10% 0 3% 0;}
    }
.gel-bg .gel-tube{width: 100%;
img{
  width: 100%;
}
}

// static pages
.expert{ text-align: center; padding: 7% 0 0 0;
  img{margin-bottom: 20px; width: 54%;}
  p{
font-family: $fontstyle-diodrum-medium;
font-style: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: $headingtext;
   span{ font-family: $fontstyle-diodrum-bold; display: block;} 
  }
} 
  .static-content{
    background: $staticbg;
    padding: 8% 5%;
    @include for-size(mobile-360){height: 60vh;}
    @include for-size(mobile-375){height: 60vh;}
    @include for-size(mobile-390){height: 60vh;}
    @include for-size(mobile-412){height: 53vh;}
    @include for-size(mobile-414){height: 56vh;}
    @include for-size(mobile-428){height: 60vh;}
    @include for-size(mobile-430){height: 65vh;}
    .heading{
      font-family: $fontstyle-diodrum-medium;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: $text-black-color;
      margin-bottom: 20px;
    }
    P{ 
      font-family: $fontstyle-diodrum;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 20px;
      color: $text-black-color;
    }
  }
  .terms{padding: 10% 4% 0 4%;
    img{ margin: 0px 0 30px -5px;}
        .heading{
          font-family: $fontstyle-diodrum-medium;
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          line-height: 30px;
          color: $text-black-color;
          margin-bottom: 20px;
        }
    .term-heading{
      font-family: $fontstyle-diodrum-bold;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $headingtext;
    margin-bottom: 20px;
    }
        p{
          font-family: $fontstyle-diodrum;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 20px;
          color: $text-black-color; 
          a{font-weight: 700; color: $text-black-color;}
         
          }
          p:last-child{
            padding-bottom: 40px;  
          }
      }
   // static pages
}



}
//home mid container end
.footer{display: flex; width: 100%; justify-content: center; margin: 7% 0 0 0;
  padding: 2% 0;
  @include for-size(mobile-360){margin: 0; padding: 9% 0;}
  @include for-size(mobile-375){margin: 0; padding: 7% 0;} 
  @include for-size(mobile-390){margin: 0; padding: 8% 0;}
  @include for-size(mobile-393){margin: 0; padding: 8% 0;}
  @include for-size(mobile-412){margin: 0; padding: 7% 0;}
  @include for-size(mobile-414){margin: 0; padding: 8% 0;}
  @include for-size(mobile-428){margin: 0; padding: 5% 0;}
  @include for-size(mobile-430){margin: 0; padding: 11% 0;}



P{font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $partnerwidthtext;
    margin: 7px 10px 0 0;
}


.footer-logo{ margin: 0 10px 0 0; width: auto; }
}
  
  
@media only screen and (min-width: 393px) and (min-height: 746px)
   { 
    .footer
     {padding: 5% 0 !important;}
   }
 

@media only screen and (min-width: 412px) and (min-height: 693px)
   { 
    .footer
     {padding: 9% 0 !important;}
   }
 // iphone 11 pro max
 @media only screen and (min-width: 393px) and (min-height: 786px)
{ .footer
  {padding: 9% 0 !important;} 
}

 // iphone 14 pro max
 @media only screen and (min-width: 414px) and (min-height: 719px) 
 and (-webkit-device-pixel-ratio: 3) { 
   .footer
  {padding: 7% 0 !important;}      
 }
 
@media only screen and (min-width: 412px) and (min-height: 746px)
{ .footer
  {padding: 8% 0 !important;} 
}
@media only screen and (min-width: 428px) and (min-height: 746px)
{ .footer
  {padding: 7% 0 !important;} 
}



//Button
.btn-primary { --bs-btn-color: none;--bs-btn-bg: none; --bs-btn-border-color: none; --bs-btn-hover-color: none;
    --bs-btn-hover-bg: none; --bs-btn-hover-border-color: none; --bs-btn-focus-shadow-rgb: none;
    --bs-btn-active-color: none;--bs-btn-active-bg: none;--bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;--bs-btn-disabled-color: none; --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;}

.btn.btn-primary{ 
    background: $ligth-btn-color;  
    border-color: transparent;
    border-radius: 30px; 
    box-shadow:  0px 2px 10px rgba(0, 0, 0, 0.25); 
    color: $whitecolor;
    font-family: $fontstyle-diodrum-bold; 
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 40px; 
    text-align: center; 
    width: 100%;
.btnwidth50{width: 50%; margin:0 auto;}
.btn-position-home{position: absolute; bottom: 20px;}

}

.btn-position{
  position: relative;
  top: 10px;
  text-align: center;
  }
  .btn.btn-primary.btposition{ margin: 20px auto 0 auto !important;}
  @media only screen and (min-width: 375px) and (min-height: 548px) 
and (-webkit-device-pixel-ratio: 2) {  
  .btn.btn-primary.btposition{ margin: 40px auto 0 auto !important;}
}
  .btn-position .btn.btn-primary.btnwidth80{width: 80%; margin:0 auto;}
  .btn.btn-primary.btnwidth90{width: 90%; margin:0 auto;}
  .btn.btn-primary.btnwidth85{width: 92%; margin:0 auto;}
.btn.btn-primary.btnresponsive{width: 96%; margin:0 auto;
  @include for-size(mobile-428){width: 93%;}
  @include for-size(mobile-414){width: 96%;}
  @include for-size(mobile-390){width: 96%;}
  @include for-size(mobile-375){width: 96%;}
  @include for-size(mobile-360){width: 96%;}
}
//Button

// top menu
.top-menu{
  margin: 12px 15px 0 17px;

  .bar1 {
    width: 30px;
    height: 4px;
    border-radius: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }

  .bar2 {
    width: 18px;
    @extend .bar1;   
  }
  &.change{
    margin: 6px 7px 0 25px;
 .bar1 {
    transform: translate(0, 10px) rotate(-45deg);
  }
  .bar2 {
    transform: translate(0, -0px) rotate(45deg);
    width: 30px;
  }
}
}
// Top menu
// card-slider
.slick-slide{ 
   // Iphone se 2022 css hack
  @media only screen and (min-width: 375px) and (min-height: 548px) 
  and (-webkit-device-pixel-ratio: 2) {  
    .card-gap {padding: 0px 15px !important;} 
  }
.card-gap {
  padding: 30px 15px;
  position: relative;

.crad-slider{
 
  .card{
    width:auto;
    padding: 25px;
    background: $card-bg;
    border-radius: 0;
    height: 450px;
    @include for-size(mobile-360){height: 400px;}
    @include for-size(mobile-375){height: 430px;}
    @include for-size(mobile-390){height: 430px;}
    @include for-size(mobile-414){height: 500px;}
    @include for-size(mobile-428){height: 480px;}
    @include for-size(mobile-430){height: 490px;}
   
    
   
   
    
h4{
  font-family: $fontstyle-diodrum-bold;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 120.5%;
color: $whitecolor;
margin: 10% 0 5% 0;
 }
    p{
font-family: $fontstyle-diodrum;
font-style: normal;
font-size: 14px;
line-height: 17px;
color: $whitecolor;
    }
  }
}
}

}
// card-slider
// Iphone se 2022 css hack
@media only screen and (min-width: 375px) and (min-height: 548px) 
and (-webkit-device-pixel-ratio: 2) {  
  .slick-dots {bottom: -40px!important;} 
}
.slick-dots{
  background: $slickdotbg!important;
  border-radius: 20px !important;
  padding: 2px 0 5px 0 !important;
  width: 100px;
  left: 35%;
  bottom: -50px;
}
.slick-dots li button:before{
  color: #EF783080!important;
  opacity: 9!important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #EF7830!important;
}
// video-home

.video-home{
h1{
  font-family: $fontstyle-diodrum;
font-style: normal;
font-size: 35px;
line-height: 120.5%;
color: $introbtn;
text-align: left;
margin: 5% 5%;
@include for-size(mobile-360){margin:6% 5% 1% 5%; font-size: 28px;}
@include for-size(mobile-375){margin: 3% 5% 1% 6%; font-size: 35px;}
@include for-size(mobile-390){margin: 6% 5% 1% 6%; font-size: 35px;}
@include for-size(mobile-414){margin: 6% 5% 1% 5%;}
@include for-size(mobile-428){margin: 6% 5% 1% 6%;}
@include for-size(mobile-430){margin: 6% 5% 1% 6%;}

&.mar-left-0{margin-left: 0;}
  }
  .mindful-section{
    display: flex;  
    padding: 10px 15px 0px 15px;
    flex-direction: column;
    .mindful-video-card{
      display: flex;
      flex-direction: row;
      padding: 30px 15px;
      border-radius: 15px;
      width: 100%;      
      background: url("../../../../../assets/images/images-v3/mindful-card-bg.svg") no-repeat;
      background-position: bottom;
      background-position-x: 0;
      margin: 0 0 20px 0;

      @include for-size(mobile-360){padding: 16px 15px;  margin: 0 0 18px 0;}
      @include for-size(mobile-375){padding: 12px 15px;  margin: 0 0 15px 0;}
      @include for-size(mobile-390){padding: 12px 15px;  margin: 0 0 15px 0;}
      @include for-size(mobile-414){padding: 15px;  margin: 0 0 15px 0;}
      @include for-size(mobile-428){padding: 15px;  margin: 0 0 20px 0;}
      @include for-size(mobile-430){padding: 22px 15px;  margin: 0 0 20px 0;}
    

      &.cardorange{ background-color: $cardorange;}
      &.cardblue{ background-color: $introbtn;}
      &.cardgreen{ background-color: $cardgreen;}
      &.carddkgreen{ background-color: $carddkgreen; margin-top:7px;}
      .card-img{
        img{width: 73%; 
          border: 1px solid #fff;
          border-radius: 50%;
          padding: 8px;
          @include for-size(mobile-360){width: 73%;}
          @include for-size(mobile-375){width: 73%;}
          @include for-size(mobile-390){width: 73%;}
          @include for-size(mobile-414){width: 85%;}
          @include for-size(mobile-428){width: 85%;}
          @include for-size(mobile-430){width: 85%;}
        
        }
      }

      .card-details{
      display: flex;
      flex-direction: row;
        .card-heading{
          font-family: $fontstyle-diodrum-bold;
          font-style: normal;
          font-weight: 700;
          font-size: 3vh;
          line-height: 130.5%;
color: $whitecolor;
text-align: left;
width: 170px;


@include for-size(mobile-360){width: 95px;margin: 0 20px 0 15px;}
@include for-size(mobile-375){width: 140px; margin: 0 0 0 12px;}
@include for-size(mobile-390){width: 132px; margin: 0 0 0 15px;}
@include for-size(mobile-393){width: 118px; margin: 0 12px 0 15px;}
@include for-size(mobile-414){width: 178px; margin: 0 0 0 15px;}
@include for-size(mobile-428){width: 198px; margin: 0 0 0 15px;}
@include for-size(mobile-430){width: 194px; margin: 0 0 0 15px;}

        }
        .card-description{
          font-family: $fontstyle-diodrum-medium;
          font-weight: 500;
          font-size: 14px;
          line-height: 120.5%;
          color: $whitecolor;
text-align: left;
width: 100px;


        }
      }

    }
  }
}
// video-home
// Theraphy header

.thearapy-header{
  width: 100%; padding: 5%; display: flex; justify-content: space-between; position: fixed;
  .backup-btn{ width: 40px;}
  
 &.header-0{background-color: $cardorange;}
 &.header-1{background-color: $headerblue;} 
 &.header-2{background-color: $headergreenlight;}
 &.header-3{background-color: $headergreen;}

 
 .thearapy-container{
   display: flex;
   .thearapy-icon{
     margin: 0 10px;
   }
   .thearapy-heading{
     font-family: $fontstyle-diodrum-bold;
 font-size: 18px;
 font-weight: 700;
 line-height: 22px;
 letter-spacing: 0em;
 text-align: left;
 color: $whitecolor;
 margin: 4px 0 0 0;
 
   }
 }
 }
 // Theraphy header



 // emotion btn
 .text-label{
  font-size: 16px  !important;
  font-family:$fontstyle-diodrum !important;
 }
.emotion-bg{
  background-color: $whitecolor; height: 100%;
  // Rating Slider
.rating-slider{
  padding:20% 6% 0% 6%;
  text-align: center;
  .rangelabel{ display: flex; margin-top: 30px;
  p{
    font-family:$fontstyle-diodrum-medium;
    font-size: 16px !important; 
    text-align: left;
    margin-bottom: 30px;
    font-weight: 400;
    color: $text-black-color;
   
  }
  :last-child{text-align: right;}
  }
 
 input[type="range"]::-webkit-slider-thumb {
    background: #ED7738 !important; 
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25)!important;}
  /* All the same stuff for Firefox */
   input[type="range"]::-moz-range-thumb {
    background: #ED7738 !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;}
  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb { 
    background: #ED7738 !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important;}
    
  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
    top: 7px !important;
  }
  .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
    top: 0px !important;
}

  p{
    color: $headerblue; 
    font-family: $fontstyle-diodrum-medium;
    font-size: 24px;  
    font-weight: 500;
    width: 60%;
    text-align: left;
    line-height: normal;
  }

  .rating-btn{
    background: $introbtn;  
    border-color: transparent;
    border-radius: 30px;    
    color: $whitecolor;
    font-family: $fontstyle-diodrum;
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 40px; 
    text-align: center; 
    width: 97%;
    margin: 40px auto;
    text-transform: uppercase;
  }

  .range-slider__wrap{ padding: 0; margin-bottom: 30%;}

  
}
      
.emotion-section{
  padding: 27% 6%;
  height: 75%;
p{
    color: $headerblue; 
    font-family: $fontstyle-diodrum-medium;
    font-size: 24px;  
    font-weight: 500;
    width: 60%;
    margin-bottom: 30px;
    line-height: normal;
}
  .morbtn{
    border: 1px solid $emotionbtncolor; 
    border-radius: 56px;  
    background-color: $emotionbtncolor;
    height: 40px; 
    color: $whitecolor; 
    font-family: $fontstyle-diodrum-medium;
    font-size: 14px;  
    font-style: normal;
    font-weight: 500;
    margin: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    padding: 0 17px;
    @include for-size(mobile-360){margin: 5px 13px 15px 0;}
    @include for-size(mobile-375){margin: 5px 12px 15px 0;}
    @include for-size(mobile-390){margin: 5px 15px 15px 0;}
    @include for-size(mobile-393){margin: 5px 18px 15px 0;}
    @include for-size(mobile-412){margin: 5px 20px 15px 0;}
    @include for-size(mobile-414){margin: 5px 20px 15px 0;}
    @include for-size(mobile-428){margin: 5px 25px 15px 0;}
    @include for-size(mobile-430){margin: 5px 25px 15px 0;}
        
    }
    .morbtn.active{background: $emotionactivebtncolor; font-family: $fontstyle-diodrum-bold;}
    .evnbtn{border: 1px solid $emotionbtncolor; @extend .morbtn; box-shadow: 0 0 10px $emotionbtncolor;}
    .evnbtn.active{font-weight: 900;} 


  } 
  .emotion-btn{
    background: $introbtn;  
    border-color: transparent;
    border-radius: 30px;    
    color: $whitecolor;
    font-family: $fontstyle-diodrum-bold;
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 43px;
    text-align: center; 
    width: 88%;
    margin: 0 auto;
    text-transform: uppercase;
    box-shadow:  0px 2px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    bottom: 15%;   
    left: 6%;

  }


}
// emotion btn

.card{
background: $cardgreen !important;
}

// Desktop Landing Page
.qr-app-container{
  width: 100%;
  background-color: $whitecolor;
  padding: 22px 0 4% 0;
.qr-app{
  display: flex;
  justify-content: space-between;
   margin:  0 auto;
   width: 60%;
  

  .qr-container{
    width: 59%;

    .qr-heading{
      color: #031C64;
      font-family: $fontstyle-diodrum-bold;
      font-size: 40px; 
      font-style: normal; 
      font-weight: 700;
      line-height: 50px;
    }
    p{
      padding: 40px 0;
      font-family: $fontstyle-diodrum;
      font-size: 19px; 
      font-style: normal; 
      font-weight: 400;
      line-height: 21px;
    }
    .qr-img{
      img{width: 25%;}
    }
  }
  .mobile-container{ 
    width: auto; text-align: right;
    img{width: 70%;}
  }
  .desktop-card-img-blue{ display: none; img{width: 100%;}}
}
}
.landing-card{width: 100%; padding: 40px auto; background-color:$slickdotbg;
  .landing-card-heading{
      font-family: $fontstyle-diodrum-bold;
      font-size: 18px; 
      font-style: normal; 
      font-weight: 400;
      color: $headerblue;
      text-align: center;
      padding: 20px 0 5px 0;
  }
  .card-container{
    display: flex;
    width: 70%;
    margin: 0 auto;
    padding: 20px 0;
    justify-content: center;
.desktop-card{
  width:30%;
  padding: 2%;
  background-color: $whitecolor;
  margin-right: 3%;
  .desktop-card-img{
    img{width: 100%;}
  }
  .card-titile{
      font-family: $fontstyle-diodrum-bold;
      font-size: 15px; 
      font-style: normal; 
      font-weight: 700;
      color: $cardgreen;
      margin: 10px 0;
      
  }
  p{
    font-family: $fontstyle-diodrum;
    font-size: 10px; 
    font-style: normal; 
    font-weight: 400;
    color: $text-black-color;
    line-height: 13px;
    
}
}
}
}
// Desktop Landing Page
.subtitles{
  display: flex;
  justify-content: right;
  text-align: right;
  /*margin: 10px 0 14px 0;*/
  @include for-size(mobile-360){margin: 1px 0 6px 0;}
  @include for-size(mobile-375){margin: 1px 0 5px 0;}
  @include for-size(mobile-390){margin: 10px 0 14px 0;}
  @include for-size(mobile-393){margin: 1px 0 2px 0;}
  @include for-size(mobile-414){margin: 10px 0 14px 0;}
  @include for-size(mobile-428){margin: 10px 0 14px 0;}
  @include for-size(mobile-430){margin: 10px 0 14px 0;}


  .subtitles-btn{ 
    /*border:2px solid $emotionbtncolor; */
    padding: 0;  
    justify-content: right; 
    letter-spacing: 4px;
    text-align: right;
    line-height: 30px;
    margin-right: -2px;
    width: -webkit-fill-available


  }
    .subtitles-btn.active{ 
      /*background: $emotionbtncolor; */
      color: $whitecolor;
    }
}

.audio-cc{
  height: 30px; width: 100%; display: flex;
  @include for-size(mobile-360){height: 21px;}
  @include for-size(mobile-375){height: 30px;}
  @include for-size(mobile-390){height: 30px;}
  @include for-size(mobile-393){height: 30px;}
  @include for-size(mobile-414){height: 30px;}
  @include for-size(mobile-428){height: 30px;}
  @include for-size(mobile-430){height: 30px;}
  
}

  @media only screen and (min-height: 892px)
{ 
.audio-cc
  {height: 30px; width: 100%; display: flex;}
}

// lotus green theme 
}
// emotion green theme model
.emotionModel-green{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .modal-dialog{
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%) !important;
  .modal-content {
    width: 90%;
    background-color: $modelgreen !important;
    border-radius: 25px;
    padding: 10px 15px !important;
    margin: 0px auto !important;
    justify-content: space-between !important;
    .text-color-danger{ color: $whitecolor;}
    .modal-header{
     border-bottom: none;
     padding-top: 5px;
     padding-right: 10px;
    .heading-text-title-popup{
    font-family: $fontstyle-diodrum-bold;
    color: $whitecolor;
    font-weight: 700;
    margin: 0;
    padding-top: 20px;
    margin-top: 20px;
    font-size: 16px;
    
  }
    }
  }
  .emotion-btn{
    background: $introbtn;  
    border-color: transparent;
    border-radius: 30px;    
    color: $whitecolor;
    font-family: $fontstyle-diodrum;
    font-size: 15px; 
    font-style: normal; 
    font-weight: 700;
    height: 40px; 
    text-align: center; 
    width: 90%;
    margin: 0 auto;
  }
  .pop-button-ohimpact{
    background: $introbtn;
    color:$whitecolor;
    font-style: normal;
    padding: 10px 16px;
    font-weight: 700;
    box-shadow:  0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin: 10px 0;
    text-transform: uppercase;
    font-family: $fontstyle-diodrum-bold;
    font-size: 14px;

     
  }
    .btn-close{ color: $whitecolor; 
      background: url(../../../../../assets/images/images-v3/close-white-icon.svg) no-repeat;
      opacity: 10;
    }
}

}
// emotion green theme model
.form-control.custom-input::placeholder{
  font-style: normal;}


.theraphy {
  padding: 5% 5% 4% 5%;
  // video{
  // @include for-size(mobile-360){height:280px;}
  // @include for-size(mobile-390){height:295px;}  
  // }
  @include for-size(mobile-360){padding: 8% 5% 0% 5%;}
  @include for-size(mobile-375){padding: 2% 5% 4% 5%;}
  @include for-size(mobile-390){padding: 2% 5% 4% 5%;}
  @include for-size(mobile-393){padding: 2% 5% 7% 5%;}
  @include for-size(mobile-414){padding: 2% 5% 4% 5%;}
  @include for-size(mobile-428){padding: 2% 5% 0 5%;}
  @include for-size(mobile-430){padding: 2% 5% 8% 5%;}

  .music-img{ width: 100%; 
    @include for-size(mobile-360){margin: 0 0 2% 0;}
  @include for-size(mobile-375){margin: 0 0 2% 0;}
  @include for-size(mobile-390){margin: 0 0 2% 0;}
  @include for-size(mobile-393){margin: 0 0 2% 0;}
  @include for-size(mobile-414){margin: 0 0 2% 0;}
  @include for-size(mobile-428){margin: 0 0 2% 0;}
  @include for-size(mobile-430){margin: 0 0 2% 0;}
}
 }

.theraphy-footer{
  background: url("../../../../../assets/images/images-v3/execute-card.svg") no-repeat ;
  display: flex;
  flex-direction: row;
  //position:absolute;
  //bottom: 0px;
  width: 100%;
  left: 0;
  padding:35px 5%;
  background-size: 100%;
  align-items: center;
  gap: 15px;
  line-height: normal;

  @include for-size(mobile-360){padding:12px 5%;}
  @include for-size(mobile-375){padding:20px 5%;}
  @include for-size(mobile-390){padding:30px 5%;}
  @include for-size(mobile-393){padding:35px 5%; margin: 62px 0 0 0;}
  @include for-size(mobile-412){padding:35px 5%;}
  @include for-size(mobile-414){padding:33px 5%;}
  @include for-size(mobile-428){padding:33px 5%;}
  @include for-size(mobile-430){padding:33px 5%;}

  p{
    margin-bottom: 0;
    color: #000;
    font-family: $fontstyle-diodrum;
    font-size: 14px;
    font-weight: 400;
    
    
    span{font-family: $fontstyle-diodrum-medium; font-weight: 500;}
    }
    
  span{img{ margin-bottom: 10px;}}
}
@media only screen and (max-height: 892px)
{ 
  .theraphy-footer {margin: 0 0 0 0;}
}
.font-14{
  font-family: $fontstyle-diodrum-medium;
  font-size:14px;
  line-height: 15px;
  margin: 0 0 0 10px;
font-weight: 400;
}


.video-theraphy{
  background-color: #F7F6E9;
  height: 330px;
}

.m-left-auto{
  margin-left: auto;
}

.play-control{
  display: flex;
  align-items: center;
  justify-content:center;
   margin: 15px 0 0 0;
   @include for-size(mobile-360){margin:6px 0;}
   @include for-size(mobile-375){margin: 10px 0 0 0;}
   @include for-size(mobile-390){margin: 15px 0 0 0;}
   @include for-size(mobile-393){margin: 11px 0 0 0;}
   @include for-size(mobile-414){margin: 15px 0 0 0;}
   @include for-size(mobile-428){margin: 15px 0 0 0;}
  @include for-size(mobile-430){margin: 15px 0 0 0;}
  img{
  margin:0 !important; 
}
}
@media only screen and (min-height: 892px)
{ 
  .play-control {margin: 26px 0;}
}
@media only screen and (min-height: 746px)
{ 
  .play-control {margin: 26px 0;}
}


.media-play{
  width: 74px;
  height: 74px;
  border-radius: 50%;
  background: #031C64;
  text-align: center;
  margin: 0 15px;
  line-height: 70px;
  //box-shadow:  0px 2px 10px rgba(0, 0, 0, 0.25);

}

.progress{
  background-color:#F7F9EE !important;
  margin-bottom: 20px;
  height: 9px!important;
    @include for-size(mobile-360){margin: 5px 0 15px 0;}
    @include for-size(mobile-375){margin: 5px 0 5px 0;}
    @include for-size(mobile-390){margin: 5px 0 5px 0;}
    @include for-size(mobile-393){margin: 5px 0 20px 0;}
    @include for-size(mobile-414){margin: 5px 0 5px 0;}
    @include for-size(mobile-428){margin: 5px 0 5px 0;}
    @include for-size(mobile-430){margin: 5px 0 5px 0;}
   
.progress-bar{
  background-color:#EF7830 !important;
}
}

.slick-arrow.slick-next{
  display: none !important;
}


.form-check .form-check-label {
  float: left;
  width: 82%;
  text-align: left;
  margin-right: 41px;
  font-family: $fontstyle-diodrum;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
video::-webkit-media-controls-play-button {
  display: none !important;
}
video::cue {
  color: #fff !important;
  background-color:#000 !important;
  font-size: 18px !important;
  font-weight: 900 !important;
  font-family: $fontstyle-lato !important;
  }
  
  //left Nav
  .nav-text a{
    font-family: $fontstyle-diodrum-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $text-black-color;
  }
  .slick-slide img {
    display: block;
    width: 100%;
}


.progressBar-green {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  background-color: #F7F9EE; 
  margin: 20px 0;
 
#scrubSlider {
  width: 100%;
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  background: none;
  margin: 7px 10px 0 0;
  }

.scrubSlider::-webkit-slider-runnable-track {
  width: 100%; 
  border-radius: 12px; 
}

// new Range slider
.range-slider {  
  align-items: center;
  width: 100%;
  height: 8px;
  overflow: hidden;
  border-radius: 16px;
 }

/* Track: webkit browsers */
.range-slider input[type="range"]::-webkit-slider-runnable-track { 
  background: #F7F9EE!important;
}


/* Thumb: webkit */
.range-slider input[type="range"]::-webkit-slider-thumb {   
  border-radius: 50%; 
  /*  slider progress trick  */
  //box-shadow: -407px 0 0 400px #f50;  
  box-shadow: -407px 0 0 400px #ED7738;  

}


input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  /*  overflow: hidden;  remove this line*/  
  /* New additions */
  height: 10px;
  background-color: #F7F9EE;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */  
  background-color: #E95E14;
  border-radius: 50%;
  border: none;
  height: 19px !important;
  width: 19px !important;
  //background: url("../../../../../assets/images/images-v3/green-range-circle.svg")no-repeat;
  //filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25)) !important;
 /* box-shadow: -407px 0 0 400px #f50; emove this line */
  // transition: .2s ease-in-out;
}

}


/*
video::cue-region{
  background-color: #FF0000 !important;
  color: #000;
}*/