//Left menu
.left-menu-icon { margin: -38px 0 0 0;
    span{
    color: $whitecolor; 
    text-align: right; 
    height: 22px; 
    font-size: 3rem; 
    font-weight: 700;}}

.navbar {
background-color: #060b26; 
height: 80px; 
display: flex; 
justify-content: flex-end;
align-items: center;
}
.menu-bars{font-size: 2.2rem; background: none;}
.close-menu{ color: #fff; font-size: 2rem; font-weight: bold;}
.nav-menu {
  background-color: #8C4CFB;
  width: 195px; 
  height: 100vh; 
  display: flex;
  justify-content: center; 
  position: fixed; 
  top: 0; right: -100%; 
  transition: 850ms;
  z-index: 1000;
}
.nav-menu.active{right: 0; transition: 350ms;}
.nav-menu.active ul{padding: 0; margin: 0;}
.nav-text {
  display: flex; 
  justify-content: flex-start; 
  align-items: center; 
  list-style: none;
  height: 60px;}
.nav-text a {
  text-decoration: none; 
  color: #fff; 
  font-family: $fontstyle-lato;  
  font-weight: 700; 
  font-size: 16px;
  line-height: 19px; 
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; 
  padding: 0 24px; 
  border-radius: 4px;}
.nav-text a:hover {
  background-color: #7B2FFF; 
  width:100%;color: #FFFFFF;}
.nav-menu-items {width: 100%;}
.navbar-toggle {
  background-color:#8C4CFB;
  width: 100%;height: 80px;
  display: flex; 
  justify-content: flex-end;
align-items: center;
}